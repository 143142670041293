<template>
  <div>
    <Overlay  style="z-index:90000;" :language="language" v-if="displayModal" :favorite="save" :title="title" :visible="displayModal" @remove="displayModal=false"/>
    <PopupApplication v-if="displayAppPopup" />
    <div v-if="this.$route.name!='checkout-app'" v-bind:class="{collaboration:true, landing:true, topped:headerFixed, empty:cart_item_nb==0}" id="cart-icon" @click="popupCartFn" >
        <img src="../../../public/assets/images/cartnew.svg" width="20" />
        <span v-if="cartItemsLoaded && cart_item_nb!=0" id="number_items" >{{cart_item_nb}}</span>
    </div>
    <div id="popup-cart" v-if="popupCart==true" @click="closeModal($event)">
        <div class="cart-content content" >
            <CartIcon
              :cart_item_nb="cart_item_nb"
              :loaded="loaded"
            />
            <div id="cross" @click="saveCart()"><img src="../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></div>
            <content-loader v-if="loaded==false || reloadingUserScans==true || cartTotalLoaded==false"
              viewBox="0 0 410 400"
              primaryColor="#f3f3f3"
              secondaryColor="#cccccc"
              >
               <circle id="cart-icon_cart" cx="400" cy="10" r="10" />
               <rect x="0" y="30" rx="2" ry="2" width="410" height="4" />
               <rect x="0" y="60" rx="2" ry="2" width="100" height="100" />
               <rect x="110" y="60" rx="3" ry="3" width="300" height="6" />
               <rect x="110" y="80" rx="3" ry="3" width="300" height="6" />
               <rect x="110" y="100" rx="3" ry="3" width="300" height="6" />
            </content-loader>
            <div v-else>
             
              <div class="progress-content">
              <div class="progress_text" v-if="amount_spent>=0">
                <span v-if="language=='fr'">Dépensez <span id="amount_spent">{{amount_spent}}</span>€ de plus pour bénéficier de la <span class="gradient">livraison offerte</span> en Europe</span>
                <span v-else>Spend <span id="amount_spent">{{amount_spent}}</span>€ more to get <span class="gradient">free delivery</span> in Europe</span>
              </div>
              <div class="progress_text_complete" v-else >
                <span v-if="language=='fr'"><span class="gradient">Livraison offerte</span> en Europe</span>
                <span v-else><span class="gradient">Free delivery</span> in Europe</span>
              </div>
             
              <div class="progressbar" ><span id="progress" :style="'width:'+progressPercent+'%;'"></span></div>
              </div>
              <div class="cart-border-top"></div>

              <CartItem
                :language="language"
                :user="user"
                :reloadingUserScans="reloadingUserScans"
                :cart_items="cart_items"
                :onlycollab="onlycollab"
                :displayDownloadRow="displayDownloadRow"
                :userScans="userScans"
                :cart="cart"
                :photos="photos"
                :toggleGlass="toggleGlass"
                :toggleFrame="toggleFrame"
                :activeToggle="activeToggle"
                :togglePosition="togglePosition"
                :displayOnMesurePopup="displayOnMesurePopup"
                :displayPopupBg="displayPopupBg"
                :displayHowItWorkPopup="displayHowItWorkPopup"
                :sizes="sizes"
                :selectedScan="selectedScan"
                :recommandedSize="recommandedSize"
                :displayScans="displayScans"
                :displayAppPopup="displayAppPopup"
                @minusCartItemEv="minusCartItem"
                @plusCartItemEv="plusCartItem"
                @removeItemEv="removeItem"
                @toggleCartEv="toggleCart"
                @toggleSelectEv="toggleSelect"
                @displayHowItWorkEv="displayHowItWork"
                @selectSizeEv="selectSize"
                @selectScanEv="selectScan"
                @displayLoginEv="displayLogin"
                @displayMesuresFnEv="displayMesuresFn"
                @displayHowItWorkFnEv="displayHowItWorkFn"
                @likePhotoEv="likePhoto"
                @toggleDisplayScansEv="displayScans=!displayScans"
                @displayAppPopupToTrueEv="displayAppPopup=true"
              />
              
              <div v-if="onlycollab==false && displayDownloadRow && userScans.length==0" class="download-row"><span class="cross" @click.prevent="displayDownloadRow=false;popupCart=true"><img src="../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="10"></span>
                  <a href="#" class="icon-dl"><img src="../../../public/assets/images/2021/05/download.png" /></a> 
                  <div v-if="language=='fr'" class="text">Télécharger l’application IOS afin d’ajuster cette monture à votre visage gratuitement. Nous scannons votre visage avec notre technologie unique. <a href="#">Télécharger ici</a></div>
                  <div v-else class="text">Download our free iOS application to fit this model to your face. We scan your face with unique technology. <a href="#">Download here</a></div>
              </div>
            </div>

            <!-- <CartDownload /> -->
           
            <CartTotal
              :language="language"
              @proceedCheckoutEv="proceedCheckout"
              :displayCartTotal="displayCartTotal"
              :loaded="loaded"
              :cart="cart"
              :cartTotalLoaded="cartTotalLoaded"
              :onlycollab="onlycollab"
              :totalAmount="totalAmount"
            />

        </div>
    </div>
  </div>
</template>

<script>
import {APIRequest, Auth, conversionApi} from "@simb2s/senseye-sdk";
import jQuery  from 'jquery';
import sendinblue  from 'sendinblue';
import fbq  from 'fbq';
import PopupApplication from '../popup/PopupApplication.vue'
import emitter from 'tiny-emitter/instance'
import { ContentLoader } from 'vue-content-loader'
// Import children
import CartDownload from "../children/Cart/CartDownloadComponent.vue"
import CartIcon from "../children/Cart/CartIconComponent.vue"
import CartItem from "../children/Cart/CartItemComponent.vue"
import Overlay from "../overlay/OverlayComponent.vue"
import CartTotal from "../children/Cart/CartTotalComponent.vue";
// Import Pinia
import { usePopupStore } from '@/store/popupStore.js';
import { useCartStore } from '@/store/cartStore.js';
import { useAuthStore } from '@/store/authStore.js';
import { useOrderItemStore } from "../../store/orderItemStore.js";
// Import SendInBlue module
import sendInBlue from '../../utils/sendInBlue/sendinblue';

export default {
  name: 'Cart',
  components: { 
    PopupApplication, 

    Overlay,
    ContentLoader, 
    CartIcon,
    CartItem,
    CartDownload,
    CartTotal,
  },
  props:['headerFixed','language',"betaAccess"],
  data() {
    return {
      currentIndexPopupLogin:0,
      user:null,
      cartTotalLoaded:false,
      reloadingUserScans:false,
      cartItemsLoaded:false,
      displayModal:false,
      title:'',
      socket: null,
      useCart: useCartStore(), 
      useAuth: useAuthStore(),
      usePopup: usePopupStore(),
      useOrderItem: useOrderItemStore(),
      cart_itemsFromStore: [],
      toggleGlass:[],
      sizes:null,
      toggleFrame:[],
      toggleTexture:[],
      displayPopupBg:false,
      togglePosition:[],
      displayHowItWorkPopup:[],
      displayOnMesurePopup:[],
      displayDownloadRow:true,
      cart:null,
      cart_items:[],
      cart_items_content: '',
      popupCart:false,
      popupLogin:false,
      userScans:[],
      loaded:false,
      cartLogo:false,
      onlycollab:true,
      photos:[],
      selectedScan:[],
      cart_item_nb:0,
      amount_spent:100,
      displayCartTotal:false,
      adding:false,
      activeToggle:[],
      recommandedSize:[],
      displayAppPopup:false,
      displayScans:false,
      activeScan:null,
      updatingFromSocket:false,
      deletingItem:false,
      totalAmount:0
    }
  },
  watch: {
    cart_items: async function() {

      if(localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth)
      {
        const userInfos = this.useAuth.user;
        const userID = this.useAuth.userID;
        if(userInfos != undefined){
          localStorage.id_user_senseye_auth=userInfos.id;
          let email = userInfos.email;

          
          //sendinblue.identify(email, properties);
        }
        else
        {

          var properties = {
           
          };
          //let email = 'user@guest.com';
          //sendinblue.identify(email);
        }
      }
      else
      {

        var properties = {
          
        };
        //let email = 'user@guest.com';
        //sendinblue.identify(email);
      }
      
      
      var event_name = "cart_updated"
   

    

      if(this.cart_items.length>0)
      {
         
      
          let sib_items = await this.formatSendInBlueItems(this.cart_items)
          
          var event_data = {
          "id": this.cart.id+"",
          "data": {
              "total": this.cart.amount,
              "currency": "EUR",
              "url": "visages.com",
              "items": sib_items
          }
          }
          
          sendinblue.track(
          event_name, /*mandatory*/
          properties, /*optional*/
          event_data /*optional*/
          )

          // fbq('track', 'Purchase', {currency: "EUR", value: this.order.amount});
          // await conversionApi.addEvent('Purchase', this.order)

      }
    },
    activeScan: async function () {
      
        await this.getRecommendedSizes()
    },
    selectedScan: async function () {
     
        await this.getRecommendedSizes()
    },
    loaded(newVal, oldVal) {
      this.cartLoaded = newVal;
    },
    popupCart: function () {
      if(this.popupCart==true)
      {
        jQuery('body').css('overflow-y','hidden');
      }
      else
      {
        jQuery('body').css('overflow-y','auto');
        this.$emit('calculateHeaderEv')
      }
    }
  },
  async mounted(){
    this.cartLoaded = this.useCart.cartLoaded;
    this.cart_itemsFromStore = this.useOrderItem.cartItem;
    await this.getCart();
    await this.getItems();
    this.cartItemsLoaded=true
    if(this.cart_items.length==0){
      this.cartTotalLoaded=true
    }

    this.loaded=true

    let cart_items_datalayer=[]
    for(let i=0;i<this.cart_items.length;i++)
    {
      cart_items_datalayer.push({
        'name': this.cart_items[i].glass.name,      // name of a product that is displayed. Always include it.
        'id': this.cart_items[i].glass.id,     			// id of a product. Always include it.
        'price': this.cart_items[i].amount,				// price of a product
        'quantity':  this.cart_items[i].quantity,
        'brand': 'Visages',				// brand/vendor of a product
        'category': 'Eyewear',			// category of a product
        //'variant': 'Silver',       		// product variant. If there are no variants, exclude this key from the dataLayer.push
        'list': 'main',					// name of a product list. See all the possible list names in the specification
        'position': i
      })
    }

    window.dataLayer  = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'eec.viewCart',   	// name of an event. In this case, always stays as eec.viewCart
      'ecommerce': {							// ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
        'currencyCode': 'EUR', 				// the currency which is currently set by a visitor                      
        'cart_content': cart_items_datalayer
      }
    });
  
    this.cartTotalLoaded=true

    this.toggleGlass=[]
        this.toggleFrame=[]
      this.toggleTexture=[]
      for(let i=0;i<this.cart_items.length;i++) {
          this.toggleGlass.push(false)
          this.toggleFrame.push(false)
          this.toggleTexture.push(false)
      }
    
    this.useOrderItem.$subscribe(async (mutation, state) => {
      if (mutation.storeId == "orderItem") {
      
        if(this.cartItemsLoaded==true) {
          this.cart_item_nb=0
          this.cart = await this.useCart.cartCopy;
          this.cart_items = this.useOrderItem.cartItem;
         
          if(this.cart.amount) {
            if(this.cart.amount!=0 && this.cart_items.length>0) {
            this.totalAmount=this.cart.amount
            this.amount_spent=100-this.cart.amount;
            this.amount_spent<0 ? this.progressPercent=100 : this.progressPercent=this.cart.amount
            this.cartTotalLoaded=true
          }
          }
          
          if(this.cart_items.length>0) {

            if(localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth)
            {
              const userInfos = this.useAuth.user;
              const userID = this.useAuth.userID;
              if(userInfos != undefined){
                localStorage.id_user_senseye_auth=userInfos.id;
                let email = userInfos.email;

                
                //sendinblue.identify(email, properties);
              }
              else
              {

                var properties = {
                
                };
                //let email = 'user@guest.com';
                //sendinblue.identify(email);
              }
            }
            else
            {

              var properties = {
                
              };
              //let email = 'user@guest.com';
              //sendinblue.identify(email);
            }
            
            
            var event_name = "cart_updated"
        
   
          

            if(this.cart_items.length>0)
            {
              
            
                let sib_items = await this.formatSendInBlueItems(this.cart_items)
                
                var event_data = {
                "id": this.cart.id+"",
                "data": {
                    "total": this.cart.amount,
                    "currency": "EUR",
                    "url": "visages.com",
                    "items": sib_items
                }
                }
                
                sendinblue.track(
                event_name, /*mandatory*/
                properties, /*optional*/
                event_data /*optional*/
                )

                // fbq('track', 'Purchase', {currency: "EUR", value: this.order.amount});
                // await conversionApi.addEvent('Purchase', this.order)

            }
            
            this.togglePosition=[]
            this.activeToggle=[]
            this.displayHowItWorkPopup=[]
            this.displayOnMesurePopup=[]

            for(var i=0;i<this.cart_items.length;i++)
            {
              this.displayHowItWorkPopup[i]=false
              this.displayOnMesurePopup[i]=false
              
                

                if(this.cart_items[i].scan!=null || this.cart_items[i].size=="onmeasure")
                {
                  this.activeToggle[i]='custom'
                  this.togglePosition[i]=0
                }
                else
                {
                  this.activeToggle[i]='size'
                  this.togglePosition[i]=50
                }
              
            }
            this.cart_item_nb=0
            this.displayCartTotal=true
            this.cartLogo=true
            for (var i=0;i<this.cart_items.length;i++)
            {
              this.cart_item_nb=this.cart_item_nb*1+this.cart_items[i].quantity*1;
              
            }
           
            this.loaded=true
          }
          else {

            this.amount_spent=100
            this.progressPercent=0

            this.displayCartTotal=false
            this.cartTotalLoaded=true
            this.cartLogo=false
            this.loaded=true
          }
        }
      }
    })
    this.togglePosition=[]
      this.activeToggle=[]
      this.displayHowItWorkPopup=[]
      this.displayOnMesurePopup=[]
      for(var i=0;i<this.cart_items.length;i++)
      {
        this.displayHowItWorkPopup[i]=false
        this.displayOnMesurePopup[i]=false
        if(this.cart_items[i].glass.type=='collection')
        {
          if(this.cart_items[i].scan!=null || this.cart_items[i].size=='onmeasure')
          {
            this.activeToggle[i]='custom'
            this.togglePosition[i]=0
          }
          else
          {
            this.activeToggle[i]='size'
            this.togglePosition[i]=50
          }
        }
        else
        {
          this.activeToggle[i]='size'
          this.togglePosition[i]=50
        }
      }
      if(this.cart.amount) {
    if(this.cart.amount!=0 && this.cart_items.length>0) {
      this.totalAmount=this.cart.amount
      this.amount_spent=100-this.cart.amount;
      this.amount_spent<0 ? this.progressPercent=100 : this.progressPercent=this.cart.amount
      this.cartTotalLoaded=true
    }
  }
    
    // if user not authenticated then we connect a new instance socket where the id will equal the cartID we just got from pinia store
    if(this.useAuth.isAuth){
      this.user=this.useAuth.getUser(localStorage.username_user_senseye_auth)
      await this.getUserScans()
    }
    this.loaded=true
    // WEBSOCKET
    
     this.socket = new WebSocket(`${process.env.VUE_APP_WEBSOCKET_URL}?cartID=${this.cart.id}`)
     this.socket.addEventListener("open", function () {
    
     });
    var _this=this
    this.socket.addEventListener("message", async function (e) {
      if(e.data === "updateCart"){
        const cart = await this.getCart();
      }
      else {
     
        let event_datas=JSON.parse(e.data)
        if(event_datas.action=="update_order_item") {
          
        }
        else if(event_datas.action=="delete_order_item") {
         
          if(_this.deletingItem==false) {
           
            //await _this.useOrderItem.deleteFromSocket(event_datas.order_item);
          }
          else {
            _this.deletingItem=false
          }
        }
        else if(event_datas.action=="update_cart") {
        
          //await _this.reloadCartItems()
          //await this.useCart.updateFromSocket();
        }
        else if(event_datas.action=="delete_cart") {
          
          await _this.useCart.deleteFromSocket();
        }
      }
    });

    await this.getSizes()

    emitter.on('display-popup-app', function () {
      this.displayAppPopup=true
    }, this);
    emitter.on('close-application-popup', function () {
      this.displayAppPopup=false
    }, this);



  

    emitter.on('select-device-cart', async function () {
         
          await this.selectSize(this.currentIndexPopupLogin,'onmeasure')
            this.togglePosition[this.currentIndexPopupLogin]=0;
            this.activeToggle[this.currentIndexPopupLogin]='custom'



      }, this);
    
    emitter.on('display-cart', function () {
      this.popupCart=true
    }, this);

    emitter.on('display-cart-load', function () {
      this.popupCart=true
      this.loaded=false
    }, this);
    
    emitter.on('add-to-cart', async function (product,variation) {
      this.popupCart = true;
      //await this.useOrderItem.addOrderItemCollab(product,variation)
      this.cart_items = this.useOrderItem.cartItem;
      //await this.addToCart(product,variation)
      //await this.updateCart();  
    }, this);

    emitter.on('add-to-cart-collection', async function (product,options,prescription) {
   
      this.loaded=false
      await this.useOrderItem.addOrderItemCollection(product,options)
      await this.setCartItems()
      await this.addToCartCollection(product,options,prescription)
      //await this.updateCart();
    }, this);

    emitter.on('add-to-cart-accessorie', async function (product) {
      await this.addToCartAccessorie(product)
      //await this.updateCart();
    }, this);

    emitter.on('identify_sib', function () {
      this.identify_sib()
    }, this);

    emitter.on('create_cart', function () {
      this.useCart.createCart()
      //this.createCart()
    }, this);

    
    if(localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth)
    {
      await this.identify_sib();
      await this.refresh_token();
    }
    await this.initselectedScans()
    await this.getRecommendedSizes()

   
  },
  methods:{
    async formatSendInBlueItems(items) {
      let sib_items=[]
      for(let i=0;i<1;i++)
      {
        if(i==0)
        {
            if(items[i].glass) {
                let item_glass={}
                item_glass.id_glass=items[i].glass.id
                item_glass.price=items[i].glass.price
                item_glass.name=items[i].glass.name
                item_glass.quantity=items[i].quantity
                item_glass.id=items[i].id
                sib_items.push(item_glass)
           }
        }
      }
      return sib_items
    },
    closePopupLogin()
    {
      //this.reloadingUserScans=true
    },
    async reloadUserContentCheckout () {
      this.reloadingUserScans=true
      this.loaded=false
     
      if(localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth) {
        this.user=this.useAuth.getUser(localStorage.username_user_senseye_auth)
        await this.getUserScans()
        
        
      }
      this.reloadingUserScans=false
      this.loaded=true
    },
    async reloadUserContent () {
      this.reloadingUserScans=true
      this.loaded=false
     
      if(localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth) {
        this.user=this.useAuth.getUser(localStorage.username_user_senseye_auth)
        await this.getUserScans()
        if(this.userScans==null || this.userScans.length==0)
        {
            //emitter.emit('display-popup-noscan')
        }
        else
        {
            //await this.selectScan(this.currentIndexPopupLogin,this.userScans[0].id,this.userScans[0].name)
            this.togglePosition[this.currentIndexPopupLogin]=0;
            this.activeToggle[this.currentIndexPopupLogin]='custom'
        }
        
      }
      this.reloadingUserScans=false
      this.loaded=true
    },
    async selectDevice()
    {
      if(this.cart_items[this.currentIndexPopupLogin].glass.custom_fit==true)
      {
        await this.selectSize(this.currentIndexPopupLogin,'onmeasure')
        this.togglePosition[this.currentIndexPopupLogin]=0;
        this.activeToggle[this.currentIndexPopupLogin]='custom'
        this.loaded=true
      }
      else
      {
        if(this.language=='fr')
        {
            this.showModal("Le sur-mesure n'est pas encore disponible pour ce modèle. Inscrivez-vous à notre newsletter pour être informé des mises à jour.")
        }
        else 
        {
            this.showModal("The custom-fit is not yet available for this model. Sign up to our newsletter to be notified when upgrades are added.")
        }
      }
    },
    async addToCartCollectionEvent(product,options,prescription) {
      
      this.loaded=false
      await this.useOrderItem.addOrderItemCollection(product,options)
      await this.setCartItems()
      await this.addToCartCollection(product,options,prescription)
      //await this.updateCart();
    },
    async setCartItems() {
      this.cart_items = await this.useOrderItem.cartItem;
    },
    async getCart(){
      await this.useCart.getCart();
      this.cart = await this.useCart.cartCopy;
      this.cart.cart_items = this.useOrderItem.cartItem;
      this.cart_items = this.useOrderItem.cartItem
      if(this.cart.amount) {
      if(this.cart.amount!=0 && this.cart.cart_items.length>0) {
        this.totalAmount=this.cart.amount
        this.amount_spent=100-this.cart.amount;
        this.amount_spent<0 ? this.progressPercent=100 : this.progressPercent=this.cart.amount
      }
    }
    },
    async getItems(){
      
      await this.useOrderItem.getOrderitemListCollab();
      this.cart_items = await this.useOrderItem.cartItem;
      this.cart_item_nb=0
      if(this.cart_items.length>0) {
        
        for (var i=0;i<this.cart_items.length;i++)
        {
          this.cart_item_nb=this.cart_item_nb*1+this.cart_items[i].quantity*1;
        }
        this.cartLogo=true
        this.displayCartTotal=true
      }
    },
    saveCart(){
      this.popupCart=false
      this.useOrderItem.updateOrderItemCollab()
    },
    popupCartFn(){
      this.popupCart=true
    },
    async reloadCartItems() {
     
      await this.getCart()
      await this.getItems()
    },

    displayMesuresFn(index){
      this.displayOnMesurePopup[index]=false;
      this.displayPopupBg=false;
    },
    displayHowItWorkFn(index){
      this.displayHowItWorkPopup[index]=false;
      this.displayPopupBg=false;
    },
     async initselectedScans() {
      for(let i=0;i<this.cart_items.length;i++) {

        if(this.cart_items[i].glass.type=='collection') {
 
          if(this.cart_items[i].scan!=null) {
              this.selectedScan[i]=this.cart_items[i].scan.name
             
          }
          else {
            this.selectedScan[i]="Selectionnez"
            
          }
        }
        else {
          this.selectedScan[i]="Selectionnez"
        }
      }

    },
    async getRecommendedSizes() {
      
      for(let i=0;i<this.cart_items.length;i++) {

        if(this.cart_items[i].glass.type=='collection') {
 
          if(this.cart_items[i].scan!=null) {
              
              let size = await APIRequest.get_scan_sizes(this.cart_items[i].scan.id, this.cart_items[i].glass.id)
              let size_regular=size[0].regular

              if(size_regular<=46) {
              this.recommandedSize[i]="XS"
             
              }
              if(size_regular<=50) {
              this.recommandedSize[i]="S"
              
              }
              if(size_regular<=52) {
              this.recommandedSize[i]="M"
           
              }
              else {
              this.recommandedSize[i]="L"
              
              }
          }
          else {
           
            this.recommandedSize[i]="M"
          }
        }
        else {
         
          this.recommandedSize[i]="M"
        }
      }

    },

    async getSizes() {
      // let sizes = await APIRequest.list('sizes')
      // this.sizes=sizes[0];
    },
    toggleCart(toggle, index) {
      if(toggle=='glass') this.toggleGlass[index]=!this.toggleGlass[index]
      if(toggle=='frame') this.toggleFrame[index]=!this.toggleFrame[index]
      if(toggle=='texture') this.toggleTexture[index]=!this.toggleTexture[index]
    },
    displayHowItWork(index){
      this.displayPopupBg=true
      if(this.activeToggle[index]=="size") {
          this.displayHowItWorkPopup[index]=true
      }
      else {
          this.displayOnMesurePopup[index]=true
      }
        //emitter.emit('display-howitwork-popup');
    },
    async selectScan(cart_item,id_scan,scan){

      let id_item=this.cart_items[cart_item].id
   
      await this.useOrderItem.updateOrderItemScan(id_item,id_scan,scan);
      this.cart_items=this.useOrderItem.cartItem
      if(scan==null)
      {
        if(this.language=='fr')
        {
          this.selectedScan[cart_item]="+ Nouveau scan"
        }
        else {
          this.selectedScan[cart_item]="+ New scan"
        }
        
      }
      else
      {
        this.selectedScan[cart_item]=scan
      }
    
      this.displayScans = false
      await this.getRecommendedSizes()
      //this.useOrderItem.updateOrderItemCollab()

    },
    async setScanNull(cart_item){

      let id_item=this.cart_items[cart_item].id
      await this.useOrderItem.updateOrderItemScan(id_item,null,null);
      this.cart_items=this.useOrderItem.cartItem
      
      //this.useOrderItem.updateOrderItemCollab()

    },
    displayLogin(index,liking,id){


    
      this.currentIndexPopupLogin=index

      if (liking == true) {

        this.$emit('displayPopupLoginEv', {review:false,saving:false,customing:false,liking:true,likingId:id})
        
        //emitter.emit('display-popup-login', false, false, false, true, id)
      }
      else {
        
        this.$emit('displayPopupLoginEv', {review:false,saving:false,customing:false,liking:false,likingId:null})
        //emitter.emit('display-popup-login', false, false, false, false);
      }

      
    },
    async selectSize(index,size){
      this.cart_items[index].scan=null
      let id_item=this.cart_items[index].id
      await this.useOrderItem.updateOrderItemSize(id_item,size);
      
      this.useOrderItem.updateOrderItemCollab()

    },

    closeModal($event)
    {
      this.$emit('calculateHeaderEv')
     
      if (jQuery($event.target).parents('.cart-content').length) {

        }
        else
        {
          if(jQuery($event.target).parent().parents('.download-row').length)
          {

          }
          else
          {
             if(jQuery($event.target).parents('.options-scan').length)
            {

            }
            else
            {
              
              if(jQuery($event.target).parents('#cart-items').length)
              {

              }
              else
              {
                if(jQuery($event.target).parent().parents('.popup-size').length)
                {

                }
                else {
                  if(jQuery($event.target).parents('.product_detail_content').length)
                  {

                  }
                  else {

                    if(jQuery($event.target).parents('.popup-photo-big').length || jQuery($event.target).hasClass('popup-photo-big'))
                    {
                      
                    }
                    else
                    {

                      if(jQuery($event.target).parents('.download-cart').length || jQuery($event.target).hasClass('download-cart'))
                      {

                      }
                      else
                      {

                        if(jQuery($event.target).hasClass('minus'))
                        {
                          this.popupCart=true
                        }
                        else
                        {
                             

                                // On update le cart en bdd
                              
                                this.popupCart=false
                                this.useOrderItem.updateOrderItemCollab()
                                this.$emit('calculateHeaderEv')

                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    },
    async refresh_token(){
      if(!this.useAuth.isAuth) return;
      await this.useAuth.refreshToken();
    },
    async identify_sib(){
      if(localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth)
      {
        const userInfos = this.useAuth.user;
        const userID = this.useAuth.userID;
        if(userInfos != undefined){
          localStorage.id_user_senseye_auth=userInfos.id;
          let email = userInfos.email;

          var properties = {
            'FIRSTNAME': userInfos.name,
            'LASTNAME' : userInfos.surname,
            'id': userInfos.id
          };
          sendinblue.identify(email, properties);
        }
        else
        {
          let email = 'user@guest.com';
          sendinblue.identify(email);
        }
      }
      else
      {
        let email = 'user@guest.com';
        sendinblue.identify(email);
      }
    },
    toggleSelect(toggle, index)
    {
       if(toggle=='size')
        {
            this.togglePosition[index]=50;
            this.activeToggle[index]=toggle
            
            
            this.setScanNull(index)
            this.selectSize(index,'onesize')
        }
        else
        {
          if(this.cart_items[index].glass.custom_fit==true)
          {
            if(this.betaAccess==true)
            {

            
              if(!localStorage.id_user_senseye_auth)
              {
                  this.displayLogin(index,false,null)
              }
              else if(this.userScans==null || this.userScans.length==0)
              {
              

                this.displayLogin(0,false,null)

                //this.$emit('displayPopupLoginEv', {review:false,saving:false,customing:false,liking:false,likingId:null})
                  //emitter.emit('display-popup-login',false,false,false,false)
              }
              else
              {
                this.selectSize(index,'onmeasure')
                  this.togglePosition[index]=0;
                  this.activeToggle[index]=toggle
              }
            }
            else
            {
              this.displayLogin(index,false,null)
            }
          }
          else {
            if(this.language=='fr')
                {
                    this.showModal("Le sur-mesure n'est pas encore disponible pour ce modèle. Inscrivez-vous à notre newsletter pour être informé des mises à jour.")
                }
                else 
                {
                    this.showModal("The custom-fit is not yet available for this model. Sign up to our newsletter to be notified when upgrades are added.")
                }
          }
        }
    },
    showModal(title) {
         
            this.displayModal = true;
            this.title = title;
        },
    async minusCartItem(e, id_item,quantity){
     if(quantity==1) {
      this.removeItem(id_item)
      //this.deletingItem=true
     }
     else {
      await this.useOrderItem.decreaseOrderItemCollab(id_item);
      this.useOrderItem.updateOrderItemCollab()
     }
     
    },
    async proceedCheckout() {
      this.loaded=false
      await this.useOrderItem.setLoadingOrderItems()
      this.$router.push({ name: 'checkout' })
      await this.useOrderItem.updateOrderItemCollab()
      
      this.popupCart=false
       this.loaded=true
    },
    async plusCartItem(e, id_item){
      await this.useOrderItem.increaseOrderItemCollab(id_item);
      this.useOrderItem.updateOrderItemCollab()
    },
    async formatSendInBlueItems(items) {
      let sib_items=[]
      for(let i=0;i<1;i++)
      {
        if(i==0)
        {
          let item_glass={}
          item_glass.id_glass=items[i].glass.id
          item_glass.price=items[i].glass.price
          item_glass.name=items[i].glass.name
          item_glass.quantity=items[i].quantity
          item_glass.id=items[i].id
          sib_items.push(item_glass)
        }
      }
      return sib_items
    },
    async likePhoto(liked,like_id,glass_id,cart_item_index)
        {
        
            if(liked==false)
            {
                
                if(localStorage.id_user_senseye_auth)
                {
                    if(localStorage.id_user_senseye_auth!=null && localStorage.id_user_senseye_auth!='')
                    {
                        let data={}
                        data.user={}
                        data.glass={}
                        data.glass.id=glass_id
                        data.user.id=parseInt(localStorage.id_user_senseye_auth)
                        await APIRequest.create('likes',data)
                    }
                   
                    this.cart_items[cart_item_index].glass.liked=true;
                    this.showOverlay('Produit ajouté aux favoris !')
                    
                }
                else{
                    this.displayLogin(0,true,glass_id)
                }
                
            }
            else
            {
                if(like_id!=null)
                {
                    await APIRequest.delete('likes',like_id)
                }
                this.cart_items[cart_item_index].glass.liked=false
            }
        },

    async addToCartCollection(product,options,prescription){
      this.loaded=false
      this.popupCart=true
      
      //fbq('track', 'AddToCart', {currency: "EUR", value: price});

      
      if(prescription=='prescription')
      {
        
        let id_item=this.cart_items[this.cart_items.length-1].id
        this.popupCart=false
        emitter.emit('display-prescription-popup', id_item, false)
      }

      this.togglePosition=[]
      this.activeToggle=[]
      this.displayHowItWorkPopup=[]
      this.displayOnMesurePopup=[]

      for(var i=0;i<this.cart_items.length;i++)
      {
        this.displayHowItWorkPopup[i]=false
        this.displayOnMesurePopup[i]=false
        if(this.cart_items[i].glass.type=='collection')
        { 
          if(this.cart_items[i].scan!=null)
          {
            this.activeToggle[i]='custom'
            this.togglePosition[i]=0
          }
          else
          {
            this.activeToggle[i]='size'
            this.togglePosition[i]=50
          }
        }
        else
        {
          this.activeToggle[i]='size'
          this.togglePosition[i]=50
        }
      }
      emitter.emit('added-to-cart');

      //this.loaded=true
    },

   async addToCartAccessorie(product){
      this.popupCart=true
      this.loaded=false
      let id_glass=product;
      let glass=await APIRequest.detail('glasses',id_glass)

      var price = 0
      price = glass.price
                
      fbq('track', 'AddToCart', {currency: "EUR", value: price});
      
 
      let amount=this.cart.amount*1+price*1;
      let Parameters = {};
      let cart_item = {};
      cart_item.color = {};
      cart_item.texture = {};
      cart_item.lense="";
      cart_item.branch=null;
      cart_item.finish="";
      cart_item.engraving_text="";
      cart_item.color.id=0;
      cart_item.quantity=1;
      cart_item.glass={};
      cart_item.glass.id=id_glass;
      cart_item.cart={};
      cart_item.cart.id=localStorage.cart_senseye_id;
      cart_item.texture.id=0;
      cart_item.measurements="";
      cart_item.amount=price;
      cart_item.shapediver_id=0;
      Parameters.cart_items = this.cart_items;
      Parameters.amount = amount;
     
      let alreadyincart=false;

      for(var i=0;i<this.cart_items.length;i++)
      {
        if(id_glass==this.cart_items[i].glass.id)
        {
          alreadyincart=true;
          Parameters={};
          let new_amount_item=this.cart_items[i].amount*1+price*1;
          let new_quantity=this.cart_items[i].quantity*1+1*1;
          Parameters.amount=new_amount_item;
          Parameters.quantity=new_quantity;
          Parameters.measurements=this.cart_items[i].measurements;
          Parameters.lense=this.cart_items[i].lense;
          Parameters.branch=this.cart_items[i].branch;
          Parameters.finish=this.cart_items[i].finish;
          Parameters.shapediver_id=this.cart_items[i].shapediver_id;
          Parameters.engraving_text=this.cart_items[i].engraving_text;
          Parameters.glass={id:this.cart_items[i].glass.id};
          Parameters.product_variation_item = this.cart_items[i].product_variation_item
          await APIRequest.update('order-items',this.cart_items[i].id, Parameters)
          this.loaded=false
        }
      }
      
      if(alreadyincart==false)
      {

        cart_item.cart={};
        cart_item.cart.id=localStorage.cart_senseye_id;
        await APIRequest.create('order-items',cart_item)
      
      }

      await conversionApi.addEvent();
      await this.updateCart();
    },
    async addToCart(product, variation){
      this.popupCart=true
      this.loaded=false
      let id_glass=product;
      let glass=await APIRequest.detail('glasses',id_glass)
      var price = 0
      // Ici si variation le price est le prix de la variation
      if(variation!=null)
      {
        var product_variation=await APIRequest.detail('product-variation-items',variation)
        price = product_variation.price
      }
      else
      {
        price = glass.price
      }       
      fbq('track', 'AddToCart', {currency: "EUR", value: price});
      let amount=this.cart.amount*1+price*1;
      let Parameters = {};
      let cart_item = {};
      cart_item.color = {};
      cart_item.texture = {};
      cart_item.lense="";
      cart_item.branch=null;
      cart_item.finish="";
      cart_item.engraving_text="";
      cart_item.color.id=0;
      cart_item.quantity=1;
      cart_item.glass={};
      cart_item.glass.id=id_glass;
      cart_item.cart={};
      cart_item.cart.id=localStorage.cart_senseye_id;
      cart_item.texture.id=0;
      cart_item.measurements="";
      cart_item.amount=price;
      cart_item.shapediver_id=0;
      Parameters.cart_items = this.cart_items;
      Parameters.amount = amount;
      if(glass.type=="collaboration")
      {
        let alreadyincart=false;
        // Verif si variation
        if(variation!=null)
        {
          // Si oui on verifie les cart items en fonction de la variation
          for(var i=0;i<this.cart_items.length;i++)
          {
            if(this.cart_items[i].product_variation_item!=null)
            {
              if(variation==this.cart_items[i].product_variation_item.id)
              {
                alreadyincart=true;
                Parameters={};
                let new_amount_item=this.cart_items[i].amount*1+price*1;
                let new_quantity=this.cart_items[i].quantity*1+1*1;
                Parameters.amount=new_amount_item;
                Parameters.quantity=new_quantity;
                Parameters.measurements=this.cart_items[i].measurements;
                Parameters.lense=this.cart_items[i].lense;
                Parameters.branch=this.cart_items[i].branch;
                Parameters.finish=this.cart_items[i].finish;
                Parameters.shapediver_id=this.cart_items[i].shapediver_id;
                Parameters.engraving_text=this.cart_items[i].engraving_text;
                Parameters.product_variation_item = this.cart_items[i].product_variation_item
                Parameters.glass={id:this.cart_items[i].glass.id};
                await APIRequest.update('order-items',this.cart_items[i].id, Parameters)
                this.loaded=false
              }
            }
          }
        }
        else
        {
          // Sinon on continue normalement
          for(var i=0;i<this.cart_items.length;i++)
          {
            if(id_glass==this.cart_items[i].glass.id)
            {
              alreadyincart=true;
              Parameters={};
              let new_amount_item=this.cart_items[i].amount*1+price*1;
              let new_quantity=this.cart_items[i].quantity*1+1*1;
              Parameters.amount=new_amount_item;
              Parameters.quantity=new_quantity;
              Parameters.measurements=this.cart_items[i].measurements;
              Parameters.lense=this.cart_items[i].lense;
              Parameters.branch=this.cart_items[i].branch;
              Parameters.finish=this.cart_items[i].finish;
              Parameters.shapediver_id=this.cart_items[i].shapediver_id;
              Parameters.engraving_text=this.cart_items[i].engraving_text;
              Parameters.glass={id:this.cart_items[i].glass.id};
              Parameters.product_variation_item = this.cart_items[i].product_variation_item
              await APIRequest.update('order-items',this.cart_items[i].id, Parameters)
              this.loaded=false
            }
          }
        }
        if(alreadyincart==false)
        {
          // Ici si variation on ajoute au form le product_variation_item
          if(variation!=null)
          {
            cart_item.product_variation_item={}
            cart_item.product_variation_item.id=variation
          }
          cart_item.cart={};
          cart_item.cart.id=localStorage.cart_senseye_id;
          await APIRequest.create('order-items',cart_item)
        }
      }
      else
      {
        this.cart_items.push(cart_item);
        Parameters.cart_items = this.cart_items;
        Parameters.promotion = this.cart.promotion;
        Parameters.delivery_amount = 0;
        Parameters.delivery_method = 0;
        this.cart=await APIRequest.update('carts',localStorage.cart_senseye_id,Parameters)

        this.amount_spent=100-this.cart.amount;
        this.amount_spent<0 ? this.progressPercent=100 : this.progressPercent=this.cart.amount
        //localStorage.cart_senseye_id=this.cart.id
        await this.reloadCartItems()
      }   
      await conversionApi.addEvent();
      await this.updateCart()
    },
    async getUserScans()
    {
      let userScans=await APIRequest.list('scans',localStorage.id_user_senseye_auth,null,20)
      this.userScans=userScans[0];
    },
    async removeItem(id_item){

      let nameProduct=''
      let idProduct=0
      let priceProduct=0
      for(let i=0;i<this.cart_items.length;i++)
      {
        if(this.cart_items[i].id==id_item)
        {
          idProduct=this.cart_items[i].glass.id
          nameProduct=this.cart_items[i].glass.name
          priceProduct=this.cart_items[i].amount
        }
      }
      window.dataLayer  = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'eec.removeFromCart',	// name of an event. In this case, always stays as eec.removeFromCart
        'ecommerce': {					// ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
          'currencyCode': 'EUR',		// the currency which is currently used by a visitor 
          'remove': {        			// name of an action. In this case, always stays as remove  	                      
          'products': [{     			// list of products removed from a cart. can include multiple products.
            'name': nameProduct,      // name of a product that was removed from a cart
            'id': idProduct,     	// id of a product
            'price': priceProduct,		// price of a product
            'brand': 'Visages',		// brand/vendor of a product
            'category': 'Eyewear',	// category of a product
            //'variant': 'Silver'     // product variant. If there are no variants, exclude this key from the dataLayer.push
          }]
          }
        }
      });

      this.deletingItem=true
      this.loaded=false
      await this.useOrderItem.clearOrderItemCollab(id_item);
      this.loaded=true            
    },
  },
}
</script>

<style scoped>

@media screen and (max-width:760px)
{
  #site-header.fixed.hidden #popup-cart {
  margin-top:66,5px !important;
}
  #cart-icon #number_items
  {
    right:3px;
    top:5px !important;
  }
  .progress_text_complete, .progress_text
  {
    line-height: 1 !important;
    font-size:11px !important;
    margin-bottom:10px;
  }
}

#site-header.fixed.hidden #popup-cart {
  margin-top:75px;
}

.progress_text_complete .gradient,.progress_text .gradient {
  background:transparent linear-gradient(90deg, #C0C0C0 0%, #0000FFE6 100%) 0% 0% no-repeat padding-box;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.popup-size .size-description .bold {
    font-weight: 600;
    text-decoration: underline;
}
.popup-size .size-description a {
    text-decoration: underline;
    color: #000;
}
.popup-size .size-description {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
}
.popup-size .size_choice {
    width: 100%;
}
.popup-size .size-name {
    color: #676767;
    font-weight: 600;
    margin-bottom: 10px;
}
#cart-icon.topped {
  
  top:28px;
}
</style>
