<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `SITE_NAME` }}</template>
  </metainfo>
   <a v-if="this.$route.name=='collection-beta-new' && betaAccess==false" href="/" class="arrow-beta">
      <img src="../public/assets/images/arrow5.png" width="50">
    </a> 

     <div class="lang-select beta" v-if="this.$route.name=='collection-beta-new' && betaAccess==false"><a  :class="{active:language=='fr'}" @click="changeLanguage('fr')">FR</a> <a  :class="{active:language=='en'}" @click="changeLanguage('en')">EN</a></div>
 
 
    

  <div id="nav" v-if="this.$route.name!='paypal-app' && this.$route.name!='add-card' && this.$route.name!='collaboration' && this.$route.name!='collaboration-glass' && this.$route.name!='checkout-app'">
    <HeaderTop :language="language" v-if="appView==false && this.$route.name!='meyro' && this.$route.name!='collection-glass-meyrowitz'"  />
    <header v-if="appView==false && this.$route.name!='meyro'  && this.$route.name!='collection-glass-meyrowitz'" :headerFixed="headerFixed" id="site-header" class="header-footer-group landing nofixed" :class="{brand:(this.$route.name=='brand' && this.$route.params.brand!='meyrowitz'), landingnew:this.$route.name=='landing' || (this.$route.name=='brand' && this.$route.params.brand=='meyrowitz'),collectionglass:this.$route.name=='collection-glass' || this.$route.name=='collection-glass-color'|| this.$route.name=='collection-glass-color-type',fixed:headerFixed}" role="banner">
      

  <HeaderComponent :betaAccess="betaAccess"  ref="headerComponent" @displayPopupLoginEv="displayPopupLogin" @changeLanguageEv="changeLanguage" :language="language"  @displaySearchPopupEv="displaySearchPopupFunction" @hideSearchPopupEv="hideSearchPopup" @calculateHeaderEv="calculateHeader" :displaySearchPopup="displaySearch" v-if="this.$route.name!='paypal-app' && this.$route.name!='add-card' && appView==false" />
   <div class="header-inner section-inner" v-if="appView==false">
        <div class="header-titles-wrapper">
            <div class="header-titles">
                <div class="site-logo faux-heading" ><a  @click="$router.push({ name: 'landing' })"  class="custom-logo-link" rel="home"><img width="548" height="93" src="../public/assets/images/2021/04/cropped-Groupe-463@2x.png" class="custom-logo" alt="VISAGES" srcset="../public/assets/images/2021/04/cropped-Groupe-463@2x.png 548w, ../public/assets/images/2021/04/cropped-Groupe-463@2x-300x51.png 300w" sizes="(max-width: 548px) 100vw, 548px"></a><span class="screen-reader-text">VISAGES</span></div>
            </div>
            <button  @click="displayModalMenu=true"  class="toggle nav-toggle mobile-nav-toggle" :class="{'fixed':headerFixed==true}" data-toggle-target=".menu-modal" data-toggle-body-class="showing-menu-modal" aria-expanded="false" data-set-focus=".close-nav-toggle">
                <span   class="toggle-inner">
                    <img src="../public/assets/images/burgernew.svg" height="auto">
                </span>
            </button>
        </div>
        <div class="header-navigation-wrapper">
          <nav class="primary-menu-wrapper" aria-label="Horizontal" role="navigation">
            <ul class="primary-menu reset-list-style">
              <!-- <li  id="menu-item-50" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-50">
                <router-link v-if="language=='fr'"  :to="{ name: 'collection', params: { toggle: 'optic' }}"  >Optique</router-link>
                <router-link v-else :to="{ name: 'collection', params: { toggle: 'optic' }}"  >Optic</router-link>
              </li>

              <li  id="menu-item-50" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-50">
                <router-link v-if="language=='fr'"  to="/collections/solar">Solaire</router-link>
                <router-link  v-else  to="/collections/solar">Solar</router-link>
              </li>  -->

              <li class="menu-item menu-item-type-post_type menu-item-object-page " id="menu-item-55">
                <router-link v-if="language=='fr'" @click="hideMegaMenus()" :to="{ name: 'collection-beta'}" >Personnaliser</router-link>
                <router-link  v-else  @click="hideMegaMenus()":to="{ name: 'collection-beta'}" >Customize</router-link>
              </li>
              
             
              <li  id="menu-item-500" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-500">
                <router-link v-if="language=='fr'"  :to="{ name: 'collection-beta'}"  >Collection BÊTA</router-link>
                <router-link v-else :to="{ name: 'collection-beta'}"  >BÊTA collection</router-link>
              </li>

              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-128">
                <router-link v-if="language=='fr'" @click="hideMegaMenus()" to="/comment-ca-marche">Comment ça marche</router-link>
                <router-link  v-else @click="hideMegaMenus()" to="/comment-ca-marche">How it works</router-link>
              </li>

             
              <MegaMenuConcept v-if="displayMegaMenuConcept" @mouseleave="displayMegaMenuConcept=false" />
              <MegaMenuCollection v-if="displayMegaMenuCollection" @mouseleave="displayMegaMenuCollection=false" />
              <MegaMenuAccessories v-if="displayMegaMenuAccessories" @mouseleave="displayMegaMenuAccessories=false" />
              <MegaMenuDownload v-if="displayMegaMenuDownload" @mouseleave="displayMegaMenuDownload=false" />

              <div  v-if="displayMegaMenuDownload || displayMegaMenuConcept || displayMegaMenuCollection || displayMegaMenuAccessories" class="overlay-megamenu contenudesk landing"></div>

            </ul>
          </nav>
        </div>
      </div>
    </header>
  </div>


  <router-view v-slot="{ Component }" :appView="appView" @loginCheckoutEv="loginCheckout" @addToCartCollectionEv="addToCartCollection"  @displayForgotPasswordEv="displayForgotPasswordPopup" @displayPopupLoginEv="displayPopupLogin" @changeBetaAccessEv="changeBetaAccess" @changeLanguageEv="changeLanguage" @loginUserEv="loginUser" :language="language" :displayModalMenu="displayModalMenu" :key="$route.url" :betaAccess="betaAccess" >
    <component ref="view" :is="Component" />
  </router-view>
  <PopupPrescription v-if="displayPrescriptionPopup" :cart_item="cart_item_prescription" :prescription_order="prescription_order" />
  <PopupLogin  :betaAccess="betaAccess" @changeBetaAccessEv="changeBetaAccess" ref="popupComponent" @reloadUserContentEv="reloadUserContent" @reloadUserContentSavingEv="reloadUserContentSaving" @displayForgotPasswordEv="displayForgotPasswordPopup" @selectUniqueSizePopupEv="selectUniqueSizePopup" @selectDevicePopupEv="selectDevicePopup" @closePopupLoginEv="closePopupLogin" v-if="displayLogin" :review="reviewPopup" :language="language" :saving="savingGlass" :customing="customingGlass" :liking="likingGlass" :likingId="likingId" />
  <PopupForgotPassword :language="language" v-if="displayForgotPassword" />
	<MobileMenu @changeLanguageEv="changeLanguage" :displayed="displayModalMenu" :language="language" />
  <PopupHowItWork v-if="displayHowItWorkPopup" />
  <PopupNoScan v-if="displayNoScan" />
  <PopupReview v-if="displayReviewPopup" />

  <MobileBottomBar v-if="displayMobileBottomBar && this.$route.name!='checkout-app'" />
   
</template>
<script>
import HeaderComponent from './components/layout/Header.vue'
import HeaderBottom from './components/layout/HeaderBottom.vue'
import HeaderTop from './components/layout/HeaderTop.vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import PopupForgotPassword from './components/elements/PopupForgotPassword.vue'
import PopupLogin from './components/elements/PopupLogin.vue'
import PopupHowItWork from './components/elements/PopupHowItWork.vue'
import PopupReview from './components/elements/PopupReview.vue'
import PopupNoScan from './components/elements/PopupNoScan.vue'
// import PopupPrescription from './components/elements/PopupPrescription.vue'
import PopupPrescription from './components/popup/PopupPrescription.vue'
import MobileMenu from './components/elements/MobileMenu.vue'
import MobileBottomBar from './components/elements/MobileBottomBar.vue'
import MegaMenuConcept from './components/elements/MegaMenuConcept.vue'
import MegaMenuCollection from './components/elements/MegaMenuCollection.vue'
import MegaMenuAccessories from './components/elements/MegaMenuAccessories.vue'
import MegaMenuDownload from './components/elements/MegaMenuDownload.vue'
import emitter from 'tiny-emitter/instance'
import {conversionApi} from "@simb2s/senseye-sdk"
import jQuery from 'jquery';
// IMPORT PINIA STORES
import { useCartStore } from '@/store/cartStore.js';
import { useAuthStore } from '@/store/authStore.js';
import { usePopupStore } from '@/store/popupStore.js';

import { useSettingsStore } from '@/store/SettingsStore.js';
import { mapStores } from 'pinia'
import { useMeta } from 'vue-meta'

export default {
  name: 'App',
  components:{
    HeaderComponent,
    PopupLogin,
    PopupForgotPassword,
    MobileMenu,
    PopupPrescription,
    HeaderBottom,
    PopupNoScan,
    PopupHowItWork,
    PopupReview,
    MegaMenuConcept,
    MegaMenuCollection,
    MegaMenuAccessories,
    MegaMenuDownload,
    MobileBottomBar,
    HeaderTop
  },
  computed: {
    // note we are not passing an array, just one store after the other
    // each store will be accessible as its id + 'Store'
    ...mapStores(useCartStore, useAuthStore)
  },
  data() {
    return {

      useCart: useCartStore(),
      useSettings:useSettingsStore(),
      useAuth: useAuthStore(),
      usePopup: usePopupStore(),
      language:'fr',
      // socket: new WebSocket("ws://localhost:3001"),
     socket: null,
     displaySearch:false,
     betaAccess:false,
     appView:false,
     hasDevice:null,
     likingGlass:false,
     likingId:null,
     displayForgotPassword:false,
     displayLogin:false,
     reviewPopup:false,
     savingGlass:false,
     customingGlass:false,
     displayNoScan:false,
     displayModalMenu:false,
     displayPrescriptionPopup:false,
     displayHowItWorkPopup:false,
     displayReviewPopup:false,
     displayMegaMenuConcept:false,
     displayMegaMenuDownload:false,
     displayMegaMenuCollection:false,
     displayMegaMenuAccessories:false,
     cart_item_prescription:null,
     displayMobileBottomBar:false,
     prescription_order:false,
     lastScrollY:0,
     headerFixed:window.scrollY>105 ? true : false,
     
    }
    
  },
  setup () {
    useMeta({
      title: 'VISAGES',
      htmlAttrs: { lang: 'fr', amp: true }
    })
  },
  watch: {
    "$route": function () {
      this.displaySearch=false
      this.displayModalMenu=false
      if(this.$route.params.lang)
      {


        this.changeLanguage(this.$route.params.lang)
       // this.$emit('changeLanguageEv', this.$route.params.lang)
      
      }
      else if (localStorage.senseye_language)
      {
        this.changeLanguage(localStorage.senseye_language)
      }
      if(this.$route.query.app)
      {
    
        this.appView=true
      }
      else
      {
        this.appView=false
      }
      this.calculateHeader()
    },
    "useAuth.userLogin":function(){
      this.userLogin(this.useAuth.userLogin)
    },
    "useAuth.reloadUserContent":function(){
      this.reloadUserContent()

    },
    "useAuth.reloadUserContentCheckout":function(){
      this.reloadUserContentCheckout()

    },
    "useAuth.userLoginCheckout":function(){
      this.userLoginCheckout(this.useAuth.userLoginCheckout)

    },
    "useAuth.userLogout":function(){
     
      this.userLogout()

    },
    "useAuth.closeLogin":function(){
      this.closePopupLogin()

    },
    "useAuth.userRegister":function(){
     
      this.userRegister(this.useAuth.userRegisterObject)

    },
    "useAuth.userRegisterCheckout":function(){
      this.userRegisterCheckout(this.useAuth.userRegisterCheckoutObject)

    },
    
    displayModalMenu: function () {
 
      if(this.displayModalMenu==true)
      {
        jQuery('body').css('overflow-y','hidden');
      }
      else
      {
        jQuery('body').css('overflow-y','auto');
      }
    },

  },
  beforeMount(){
    this.assignPopupValues();
  },
  async mounted(){
    window.CSS.registerProperty({
        name: "--p",
        syntax: "<percentage>",
        inherits: false,
        initialValue: "0%",
        });  
    if(this.$route.params.lang)
      {
        this.changeLanguage(this.$route.params.lang)
       // this.$emit('changeLanguageEv', this.$route.params.lang)
      }
      else if (localStorage.senseye_language)
      {
        this.changeLanguage(localStorage.senseye_language)
      }
    await this.useSettings.loadSettings()
    if(this.useSettings.displayMobileBottomBar==true) {
        this.displayMobileBottomBar=true
    }
    else {
      this.displayMobileBottomBar=false
    }
    // SOCKET FUNCTIONS if user authenticated
    // ---------------------------------------------
    await this.checkAuthUser()
    if(this.useAuth.isAuth)
    {
      await this.checkBetaStatus(this.useAuth.user.email)
    }
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    var isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;
    if(isInstagram)
    {
      jQuery('body').css('height',jQuery(window).height()+'px');
    }
    emitter.on('closeMobileBottomBar', function () {
      this.displayMobileBottomBar=false
    }, this);
    emitter.on('closePopupNoScan', function () {
      this.displayNoScan=false
    }, this);
    emitter.on('closePopupLogin', function () {
      this.displayLogin=false
      this.displayForgotPassword=false
      this.displayModalMenu=false
    }, this);
    emitter.on('login', async function (username,password) {
      await this.loginUser(username,password,false)
    }, this);
     emitter.on('register', async function (username,password,name,phone,surname,email) {
      await this.registerUser(username,password,name,phone,surname,email)
    }, this);

    emitter.on('display-popup-noscan', function () {
       this.displayNoScan=true
    }, this);

    emitter.on('display-popup-login', function (review=false, saving=false, customing=false, liking=false, likingId=null) {

      if(this.hasDevice==null || this.hasDevice==false || review!=false || liking!=false || saving!=false)
      {
        this.displayLogin=true
        this.reviewPopup=review
        this.savingGlass=saving
        this.likingGlass=liking
        this.likingId=likingId
        this.customingGlass=customing
      }
      else {
        if(customing==true)
        {
          emitter.emit('select-device-collectionglass')
        }
        else {
          emitter.emit('select-device-cart')
        }
        
      }
       
    }, this);

    emitter.on('display-prescription-popup', function (id_item, order) {
       this.cart_item_prescription=id_item
       this.prescription_order=order
       this.displayPrescriptionPopup=true
    }, this);
    emitter.on('close-prescription-popup', function () {
       this.displayPrescriptionPopup=false
    }, this);
    emitter.on('display-howitwork-popup', function () {
       this.displayHowItWorkPopup=true
    }, this);
    emitter.on('close-howitwork-popup', function () {
       this.displayHowItWorkPopup=false
    }, this);

    emitter.on('display-review-popup', function () {
       this.displayReviewPopup=true
    }, this);

    emitter.on('close-review-popup', function () {
       this.displayReviewPopup=false
    }, this);

    emitter.on('hide-megamenus', function () {
       this.hideMegaMenus()
    }, this);

    

    jQuery(window).scroll(function(){

      let limitHeader
 
      if(window.innerWidth>1024)
      {
        limitHeader=105
      }
      else
      {
        limitHeader=90
      }
      if(window.scrollY>limitHeader) {


        this.headerFixed=true

       
        jQuery('#site-header').removeClass('nofixed')
        jQuery('#site-header').removeClass('nofixed')
        jQuery('#site-header').addClass('fixed')

        jQuery('#cart-icon').addClass('topped')


        if(this.lastScrollY>window.scrollY)
        {
          jQuery('#site-header').removeClass('hidden')
          jQuery('#site-header').addClass('show')
        }
        else {
          jQuery('#site-header').removeClass('show')
          jQuery('#site-header').addClass('hidden')
          
        }


      }
      else {
        jQuery('#site-header').addClass('nofixed')
        jQuery('#cart-icon').removeClass('topped')
        jQuery('#site-header').removeClass('fixed')
      }

      this.lastScrollY=window.scrollY


      if(window.innerWidth<=760) {
       
        

        if(window.scrollY>105){
          this.headerFixed=true
          jQuery('.nav-toggle').addClass('fixed')
        }
        else {
          jQuery('.nav-toggle').removeClass('fixed')
        }
        
        if(this.lastScrollY>window.scrollY)
        {
            jQuery('.nav-toggle').removeClass('hiding')
          jQuery('.nav-toggle').addClass('showing')
        }
        else {
        

          jQuery('.nav-toggle').removeClass('showing')
          jQuery('.nav-toggle').addClass('hiding')
        }
        this.lastScrollY=window.scrollY
      }
      else {
        this.headerFixed=false
        jQuery('.nav-toggle').removeClass('fixed')
      }
      
    });
  },
  async updated() {
   
  },
  methods:{
    async checkBetaStatus(email)
    {
      let status = await APIRequest.check_status_beta(email)
      if(status.status=="ok"  || status.status==true)
      {
          this.changeBetaAccess(true)
      }
    },
    userRegisterCheckout(user){
      if(this.$refs.view.userRegisterCheckout)
      {
        this.$refs.view.userRegisterCheckout(user);
      }
    },
    userRegister(user)
    {
      if(this.$refs.view.userRegister)
      {
        this.$refs.view.userRegister(user);
      }
      if(this.$refs.popupComponent)
      {
        this.$refs.popupComponent.userRegister(user);
      }
    },
    userLogout(){
      this.$refs.view.userLogout();
    },
    async userLogin (user) {

        if (user != null && user != false) {
            await this.useAuth.getUser(localStorage.username_user_senseye_auth);
            this.initWebsocketUser()
            if(this.$refs.popupComponent)
            {
              this.$refs.popupComponent.userLogin(user);
            }
            if(this.$refs.view.userLogin)
            {
              this.$refs.view.userLogin(user);
            }
            //this.$refs.view.reloadUserContent()
        }
        else {
          if(this.$refs.view.userLogin)
            {
              this.$refs.view.userLogin(null);
            }
            if(this.$refs.popupComponent)
            {
              this.$refs.popupComponent.userLogin(null);
            }
            this.displayLoginForm = true
            // console.log('user null')
            this.errorLogin = 'Vos identifiants sont incorrects.';
            this.loaded = true
            
        }
    },
    async userLoginCheckout (user) {
      if(this.$refs.view.userLoginCheckout)
      {
        this.$refs.view.userLoginCheckout(user);
      }
    },
    displayForgotPasswordPopup(){
      this.displayForgotPassword=true
    },
    closePopupLogin() {
      this.$refs.headerComponent.closePopupLogin();
      this.displayLogin=false
      this.displayForgotPassword=false
      this.displayModalMenu=false
    },
    async loginCheckout(args)
    {
      await this.loginUser(args.username,args.password,true)
    },
    addToCartCollection(product,options,prescription) {
      this.$refs.headerComponent.addToCartCollection(product,options,prescription);
    },
    reloadUserContentCheckout()
    {
      this.$refs.headerComponent.reloadUserContentCheckout()
      if(this.$refs.view.reloadUserContent)
      {
        this.$refs.view.reloadUserContent()
      }
    },
    reloadUserContent()
    {
      this.$refs.headerComponent.reloadUserContent()
      if(this.$refs.view.reloadUserContent)
      {
        this.$refs.view.reloadUserContent()
      }
    },
    reloadUserContentSaving()
    {
      if(this.$refs.view.reloadUserContentSaving)
      {
        this.$refs.view.reloadUserContentSaving()
      }
    },
    selectUniqueSizePopup(){
      this.hasDevice=false
    },
    selectDevicePopup(customing=false) {

      if(customing==true)
      {
        
        this.$refs.view.selectDeviceCollectionGlass()
        //emitter.emit('select-device-collectionglass')
      }
      else {

        this.$refs.headerComponent.selectDevice();
  
        //emitter.emit('select-device-cart')
      }
    this.hasDevice=true
    },

    displayPopupLogin(args) {


      if(this.hasDevice==null || this.hasDevice==false || args.review!=false || args.liking!=false || args.saving!=false)
      {
        this.displayLogin=true
        this.reviewPopup=args.review
        this.savingGlass=args.saving
        this.likingGlass=args.liking
        this.likingId=args.likingId
        this.customingGlass=args.customing
      }
      else {
        if(args.customing==true)
        {
          
          this.$refs.view.selectDeviceCollectionGlass();
        
        }
        else {

          this.$refs.headerComponent.selectDevice();
         
        }
        
      }
    
    },

    changeLanguage(language) {
      this.language=language
      localStorage.senseye_language=language
      //document.location.reload()
    },
    changeBetaAccess(access) {
      this.betaAccess=access
      
      //document.location.reload()
    },
    hideSearchPopup(){
      this.displaySearch=false
    },
    displaySearchPopupFunction(){
      this.displaySearch=true
    },
    calculateHeader() {
    
      if(window.scrollY>105) {
        this.headerFixed=true

        jQuery('#cart-icon').addClass('topped')
      }
      else {
        this.headerFixed=false
      }
    },
    async checkAuthUser() {
    
      if(this.useAuth.isAuth){

        await this.useAuth.getUser();


       this.initWebsocketUser()
      }
      else {
      
      }
    },
    displayMegaMenu(menu) {

      if(menu=="concept") {
        this.displayMegaMenuConcept=true
        this.displayMegaMenuDownload=false
        this.displayMegaMenuCollection=false
        this.displayMegaMenuAccessories=false
      }
      if(menu=="download") {
        this.displayMegaMenuConcept=false
        this.displayMegaMenuDownload=true
        this.displayMegaMenuCollection=false
        this.displayMegaMenuAccessories=false
      }
      if(menu=="accessoires") {
        this.displayMegaMenuConcept=false
        this.displayMegaMenuDownload=false
        this.displayMegaMenuCollection=false
        this.displayMegaMenuAccessories=true
      }
      if(menu=="collection") {
        this.displayMegaMenuConcept=false
        this.displayMegaMenuDownload=false
        this.displayMegaMenuCollection=true
        this.displayMegaMenuAccessories=false
      }

    },
    hideMegaMenus() {
        this.displayMegaMenuConcept=false
        this.displayMegaMenuDownload=false
        this.displayMegaMenuCollection=false
        this.displayMegaMenuAccessories=false
    },
    async parseJwt (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    },
    async loginUser(username,password,checkout=false)
    {
      await this.useAuth.loginUser(username,password,checkout)
    },
    initWebsocketUser() {

      if(this.socket!=null) {
        // WEBSOCKET
        // // We subscribe to a private socket channel with id of the current user
        this.socket = new WebSocket(`${process.env.VUE_APP_WEBSOCKET_URL}?userID=${this.useAuth.userID}`)
        
        this.socket.addEventListener("open", function () {
      
        });
        emitter.on('logoutSocket', function () {
          this.socket.send("logout");
        }, this)
      
        var _this=this
        this.socket.addEventListener("message", async function (e) {
          
          if(e.data === "logout"){
  
            await _this.useAuth.handleLogout();
          }
        });
      }
      
    },
    async registerUser(username,password,name,phone,surname,email){

      const userObj = {username,password,name,phone,surname,email}
      await this.useAuth.registerUser(userObj);
   
    },
     assignPopupValues(){
      this.displayForgotPassword = this.usePopup.displayForgotPassword
      this.displayLogin = this.usePopup.displayLogin
      this.displayNoScan = this.usePopup.displayNoScan
      this.displayModalMenu = this.usePopup.displayModalMenu
      this.displayPrescriptionPopup = this.usePopup.displayPrescriptionPopup
      this.displayHowItWorkPopup = this.usePopup.displayHowItWorkPopup
      this.displayReviewPopup = this.usePopup.displayReviewPopup
      this.displayMobileBottomBar = this.usePopup.displayMobileBottomBar
    },
    
  }
}
</script>

<style>
  @import '../public/style.css';

  


  #site-header.fixed.show {
    transform: translateY(0%);
  }
  #site-header.fixed.hidden {
    transform: translateY(-100%);
  }
  #site-header.fixed{
    transition:all 0.3s ease-in-out;
  }
  #site-header.landingnew.headerbeta .header-inner
  {
    width:100% !important;
    display: inline-block !important;
    justify-content: center !important;
    
  }

  #site-header.landingnew.headerbeta .header-titles-wrapper
  {
    max-width: 100% !important;
  }
  #site-header.landingnew.headerbeta .primary-menu-wrapper,
  #site-header.landingnew.headerbeta #account-icon,
  #site-header.landingnew.headerbeta #search-icon,
  #site-header.landingnew.headerbeta #cart-icon,
  body #site-header.landing.headerbeta .nav-toggle
  {
    display: none !important;
  }

  /* #site-header.landingnew.nofixed #menu-item-55 {
   opacity:0 !important;
  } */
  
   #site-header.nofixed #menu-item-55 {
   opacity:0 !important;
  }
  #site-header.landingnew.nofixed .lang-select a.active
  {
    color:#fff !important;
  }

  #site-header.landingnew.nofixed #cart-icon #number_items
{
  color:#000 !important;
  background:#fff !important;
}
#site-header.landingnew.nofixed .toggle-inner img,
  #site-header.landingnew.nofixed .search-input-top img,
  #site-header.landingnew.nofixed #account-icon img,
  #site-header.landingnew.nofixed #cart-icon img,
  #site-header.landingnew.nofixed .site-logo img
  {
    filter: brightness(0) invert(1);
  }
  #site-header.landingnew.headerbeta.nofixed .site-logo img
  {
    display:none !important;
  }
  #site-header.landingnew.nofixed {
    background:transparent;
    box-shadow: none;
  }
  #site-header.landingnew.nofixed .primary-menu #menu-item-50 a
  {
    border: 0.5px solid #fff !important;
  }
  #site-header.landingnew.nofixed .primary-menu > li a {
    color:#fff !important;
    
  }
  .primary-menu li.menu-item-128:hover a{
    text-decoration: underline !important;
  }
  #menu-item-500 a {
    line-height: 1;
    color:#fff !important;
    border-radius: 10px;
    padding: 15px 30px;
    background:#000;
    border: 0.5px solid black;
    margin: 0 0 0 1rem;
    position: relative;
    font-weight: 600;
    top:-3px;
    font-size:16px;
}



#site-header.landingnew.nofixed .primary-menu > li#menu-item-500 a:hover {
      color: #000 !important;
      font-weight: 600;
  }

   #menu-item-50 a
  {
    
    color:#000;
    font-weight: 600;
  }
  #menu-item-55 a:hover
  {
    color:#fff !important;
    background:#000;
  }
  #menu-item-55 a
  {
    line-height: 1;
    color:#000 !important;
    border-radius: 10px;
    padding: 15px 30px;
    background:#fff;
    border: 0.5px solid black;
    margin: 0 0 0 1rem;
    position: relative;
    font-weight: 600;
    top:-3px;
    font-size:16px;
  }
  .primary-menu > li#menu-item-500 a:hover
  {
    background:transparent;
  }
   .primary-menu > li#menu-item-500 a:hover
  {
    color:#000 !important;
  }
  .primary-menu > li#menu-item-50 a:hover
  {
    background:#000;
  }
   .primary-menu > li#menu-item-50 a:hover
  {
    color:#fff !important;
  }
  #site-header.landingnew.nofixed .primary-menu > li#menu-item-50 a:hover
  {
    background:#fff !important;
  }
  #site-header.landingnew.nofixed .primary-menu > li#menu-item-50 a:hover
  {
    color:#000 !important;
  }

  #menu-item-50,  #menu-item-500, #menu-item-55
  {
    padding-top:0 !important;
  }
  #menu-item-50 a
  {
    line-height: 1;
    border-radius: 25px;
    padding:10px 40px;
    border: 0.5px solid black;
    margin: 0 0 0 1rem;
    position:relative;
 
  }
 
  .toggle-inner img
  {
    width:20px;
    height:auto;
  }
  html {
    background: transparent linear-gradient(90deg, #CBDCF8 0%, #DDD5F7 100%) 0% 0% no-repeat padding-box;
     padding:0;
    margin:0;
    width:100%;
  }
  body {
    background:#fff;
    padding:0;
    margin:0;
    width:100%;
  }
  .menu-modal
  {
    width:100vw;
    height:100vh;
  }

  .lang-select.beta {
  position:fixed;
  right:30px;
  z-index:107;
  top:28px;
}
.lang-select.beta a.active {
  color:#fff;
}
.lang-select.beta a {
  text-decoration: none;
  cursor: pointer;
  margin-right:15px;
  color:#ACACAC;
  font-size:15px;
}

  @media screen and (max-width: 1024px)
  {
  .lang-select.beta
    {
      display: block !important;
    }
  }

  @media screen and (max-width: 760px)
  {
    
    .lang-select.beta
    {
      display: block !important;
      top:17px !important;
      right:10px !important;
    }
    .custom-logo-beta
    {
      top:19px !important;
    }
    .arrow-beta img
    {
      width:35px !important;
    }
    .arrow-beta
  {
    position:fixed;
    left:20px !important;
    top:18px !important;
   

  }

    
    .collaboration#cart-icon {
        right: 25px  !important;
    }
  }
  .overlay-megamenu.landing 
  {
    top:109px;
  }
  .overlay-megamenu {
    position:fixed;
    top:80px;
    z-index: 99999;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.5);
  }

  .page-template-home-closed #site-header{
    display:none;
  }

  #site-header {
      padding-top: 5px;
    
      padding-bottom: 5px;
  }

  .page-template-template-contact #site-header
  {
        background-color: transparent;
  }

  .page-template-collab #site-header .site-logo
  {
    display:none !important;
  }
  .page-template-collab #site-header
  {
    display:none;
  }
  
  #site-header.fixed {
    position:fixed;
    background:#fff;
    top:0;
    width:100%;
  }
 
</style>
