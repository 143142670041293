<template>
  <div id="search-icon" :class="{landing: true}">
    <div class="search-input-top">
      <form action="/" @submit.prevent="submitSearch" >
        <input type="text"  :placeholder="language=='fr' ? 'Rechercher' : 'Search'" v-model="searchField" />
      </form>
     <img src="../../../public/assets/images/searchnew2.svg" width="20"  @click="displaySearchPopupFunction();search();"  />
     
    </div>
  </div>

  <div class="search-popup" v-if="displaySearch">
    <div class="content" :class="{full:sent==true}">
      <img class="cross" width="46" src="../../../public/assets/images/cross.svg" @click="hideSearchPopup" />
      <form @submit.prevent="search()" class="search-form">
        <input type="text" v-model="searchField" placeholder="Type to search" /> 
        <button type="submit"><span class="arrow"></span></button>
      </form>

      <div class="head" v-if="sent==true">
          <div class="results">{{glasses.length}} RÉSULTATS</div> 
          <a class="clear" @click="searchField=''">EFFACER LA RECHERCHE</a>
        </div>
      <br /><br />

      <div class="collection home-section-new" v-if="sent==true">
        
        <content-loader v-if="loaded==false"
            viewBox="0 0 520 200"
            primaryColor="#f3f3f3"
            secondaryColor="#cccccc"
            >

            <rect x="20" y="0" rx="0" ry="0" width="150" height="150" />

            <rect x="20" y="160" rx="3" ry="3" width="150" height="6" />

            <rect x="180" y="0" rx="0" ry="0" width="150" height="150" />

            <rect x="180" y="160" rx="3" ry="3" width="150" height="6" />

            <rect x="350" y="0" rx="0" ry="0" width="150" height="150" />

            <rect x="350" y="160" rx="3" ry="3" width="150" height="6" />
        
            
        </content-loader>
        
        
        <div class="products" v-if="loaded" @click="displaySearch=false">
        <CollectionProduct  
            :beta="true"
            :photos_solar="photos_solar"
            :glasses="glasses"
            :selectedFilter="selectedFilter"
            :photos="photos"
            :language="language"
            :colors="colors"
            :selectedColorFilter="selectedColorFilter"

            @likePhotoEv="likePhoto"
            />
            </div>

        <!-- <div class="products" v-if="loaded">

              <div class="carousel-cell product" v-for="(glass, index) in glasses" :key="glass.id" >
                <a  v-if="glass.type=='collection'" :href="'/collection/'+glass.id"></a>
                <a  v-else  :href="'/collaboration/'+glass.id"></a>
                    <img :src="photos[index]" class="product-image" />
                    <div v-if="glass.type=='collection'" class="personnalisation-actions">
                        <div class="colors-count">{{glass.colors.length}} <span class="pls">+</span> couleurs</div>
                        <div class="personnalisation-button">Personnaliser</div>
                    </div>
                    <div class="like" @click="likePhoto(glass.liked,glass.like_id,index)">
                        <img v-if="glass.liked==false" src="../../../public/assets/images/heart.svg" width="26" />   
                        <img v-else src="../../../public/assets/images/heart.png" width="26" />
                    </div>
                    
                    <div class="bottom">
                        <span class="product-title">{{glass.name}}</span>
                        <span class="product-price">À partir de {{glass.price}}€</span>
                    </div>
                
            </div>
        </div> -->
      </div>
    </div>

  </div>
    
</template>

<script>
import {APIRequest, Auth, conversionApi} from "@simb2s/senseye-sdk";
import jQuery  from 'jquery';
import sendinblue  from 'sendinblue';
import fbq  from 'fbq';
import CollectionProduct from '../children/Collection/CollectionProductComponent.vue';
// import PopupApplication from '../popup/PopupApplication.vue'
import emitter from 'tiny-emitter/instance'
import { ContentLoader } from 'vue-content-loader'

export default {
  components: { 
    // PopupApplication, 
    CollectionProduct,
    ContentLoader 
  },
  name: 'Cart',
  props:['displaySearchPopup',"language"],
  data() {
    return {
      displaySearch:false,
      colors:[],
      photos:[],
      searchField:'',
      sent:false,
      loaded:true,
      photos:[],
      photos_solar:[],
      selectedFilter:'solar',
      glass:null,
      glasses:null,
    }
  },
  watch:{
    displaySearchPopup:function(){
      
      this.displaySearch=this.displaySearchPopup
    },
    searchField:function(){
      if(this.searchField=='')
      {
        this.sent=false
      }
    }
  },
  async mounted(){
   
  
  },
  methods:{
    submitSearch(e) {
      e.preventDefault()
      //this.displaySearch=true
      this.displaySearchPopupFunction()
      this.search()
    },
    hideSearchPopup(){
      this.searchField=''
      this.$emit('hideSearchPopupEv')
    },
    displaySearchPopupFunction(){
    
    this.$emit('displaySearchPopupEv')
  },
    async likePhoto(liked,like_id,glass_index)
        {
            if(liked==false)
            {
                if(localStorage.id_user_senseye_auth)
                {
                    if(localStorage.id_user_senseye_auth!=null && localStorage.id_user_senseye_auth!='')
                    {
                        let data={}
                        data.user={}
                        data.glass={}
                        data.glass.id=this.glasses[glass_index].id
                        data.user.id=parseInt(localStorage.id_user_senseye_auth)
                        await APIRequest.create('likes',data)
                    }

                    this.glasses[glass_index].liked=true;
                    if(localStorage.id_user_senseye_auth)
                    {
                        this.showOverlay('Produit ajouté aux favoris !')
                    }
                }
                else{
                    this.displayLogin(true,this.glasses[glass_index].id)
                  
                }
                
            }
            else
            {
                if(like_id!=null)
                {
                    await APIRequest.delete('likes',like_id)
                }
                this.glasses[glass_index].liked=false;
            }
        },
    async search() {
      if(this.searchField!="")
      {
        this.loaded=false;
        await this.getGlasses();
        this.loaded=true
        this.sent=true
      }
      else
      {
      
        this.displaySearchPopupFunction()
      }
    },
     async getGlasses()  {
        let glasses=await APIRequest.search(this.searchField)
        this.glasses=glasses[0]


        this.photos=[]
        
                this.photos_solar=[]
                this.colors=[]
                
                for(let i=0;i<this.glasses.length;i++)
                {
                    this.loadingColors=true
                    let request = "?glass=" + this.glasses[i].id
                    let colors = await APIRequest.list_with_request('colors', request)
                    this.colors[i]=colors[0]
                    let photo=this.glasses[i].photos
                    let photos=this.glasses[i].photos;
                    photo=photos[0];
                    this.photos.push(photo)
                    this.photos_solar.push(this.glasses[i].photo_solar)
                }

    },
  },

}
</script>
<style scoped>

#search-icon.landing {
  top: 22px;
}
#search-icon
{
	text-decoration: none;
	font-size:15px;
	color:#000;
	position: absolute;
    right: 135px;
    top: 27px;
	
    cursor: pointer;
    z-index: 102;
}
.head
{

  display: inline-block;
  font-size:16px;
  margin-left:15%;
}
.head .clear
{
    display:inline-block;
    color:#000;
    margin-left:30px;
    text-decoration: none;
    padding-bottom:10px;
    cursor: pointer;
    border-bottom:1px solid black;
  vertical-align: top;
}
.products {
  margin-bottom:200px;
}
.head .results
{
  display:inline-block;
  vertical-align: top;
}
.collection
{
  position: relative;
}
.search-popup div.products
{
  width:80%;
  margin-left:10%;
  text-align: left !important;
}
.search-popup button
{
  background:transparent;
  position: absolute;
  right:0;
}
.search-popup .cross
{
  position:fixed;
  right:20px;
  top:120px;
  cursor:pointer;
}
.search-popup .content input::placeholder
{
  color:#DEDEDE;
}
.search-popup .content .search-form
{
  position: relative;
  margin-top:15px;
  margin-bottom:0px;
  left:10%;
  width:80%;
  max-width:640px;

}
.search-input-top {
  position:relative;
}
.search-input-top img {
  position:absolute;
  left:10px;
  top:10px;
}


.search-input-top input {
  border-radius:10px;
  background:#F5F5F5;
  color:#ACACAC;
  font-size:15px;
  line-height:1;
  border:0;
  max-width:225px;
  min-width:180px;
  padding-top:10px;
  padding-bottom:10px;
  padding-right:20px;
  padding-left:35px ;
}
.search-popup .content input
{ 
  border-top:0;
  color:#000;
  border-left:0;
  border-radius: 0;
  padding-bottom:20px;
  width:100%;

  background:transparent;
  border-right:0;
  font-size:32px;
  border-bottom: 1px solid black;
}
.search-popup .content.full
{
max-height:100vh;
overflow: auto;
}

.search-popup .content
{
 
  position:absolute;
  top:0;
  left:0;
  z-index:100;
  background:#fff;
  width:100%;
  min-height:389px;
}
#site-header.fixed .search-popup
{
  top:75px;
}
.search-popup
{
  background-color:rgba(0,0,0,0.5);
  position:fixed;
  top:129px;
  left:0;
  z-index:99;
  width:100%;
  height:calc(100vh - 81px);
  
  overflow: hidden;
  animation: animslide 0.8s ease;
}
@keyframes animslide
{
  0%{
   
    min-height:0;
    max-height:0;
  }
  100%
  {
    min-height:389px;
    max-height:100%;
  }
}

.title-collection .outline
{
    background-image: -webkit-linear-gradient(#000 50%, #000 50%);
    background-position: 0 0;
    background-size: 0% 0%;
    text-stroke: 1px #000;
    -webkit-text-stroke: 1px #000;
    color: #fff;
    white-space: nowrap;
    color: transparent;
}
.title-collection
{
    font-size:70px;
}
.collection-description
{
    text-align:center;
    max-width:700px;
    width:70%;
    margin-left:auto;
    margin-right:auto;
}
.collection-filter .item:nth-child(2)
{
width:27%;
}
.collection-filter .item:first-child
{
width:25%;
}
.collection-filter .item:nth-child(3)
{
width:48%;
}
.collection-filter .item
{
    
    cursor:pointer;
    display:inline-block;
}
.collection-filter .bar
{
    transition:all 0.5s ease-in-out;
    width:33%;
    height:2px ;
    background:#000;
    position:absolute;
    bottom:0;
}

.collection-filter
{
    position:relative;
    font-size:18px;
    text-align: center;
    width:380px;
    margin-left:auto;
    margin-right:auto;
    border-bottom:0.5px solid #808080;
    padding-bottom:10px;
}
.collection-text
{
    font-size:13px;
}
@media screen and (min-width:760px) and (max-width:1025px){
  .search-popup .content
  {
    min-height:190px;
  }
  .search-popup div.products
  {
    width:100%;
    margin-left:4%;
  }
  .head .clear
  {
    padding-bottom:1px;
  }
  .head
  {
    display:block;
    position:relative;
    top:65px;
    text-align: center;
    margin-left:0px;
    font-size:12px;
    margin-bottom:70px;
  }
  .search-popup .content .search-form
  {
    top:45px;
  }
  .search-popup .cross
  {
    top:80px;
  }
  .search-popup .content button 
  {
    padding:0;
    bottom:10px;
  }
  .search-popup .content input
  {
    padding-bottom:10px;
    font-size:22px;
  }
  .search-popup
  {
    top:80px;
  }
  body #search-icon
  {
    right:120px;

  }
 
  .search-input-top img
  {
    left:0;
 
    top:0;
    position: relative;
  }
  body .search-input-top input
	{
		display:none;
	}
}
@media screen and (max-width:760px) {
  .search-popup .content
  {
    min-height:190px;
  }
  .search-popup div.products
  {
    width:90%;
    margin-left:0%;
  }
  .head .clear
  {
    padding-bottom:1px;
  }
  .head
  {
    display:block;
    position:relative;
    top:65px;
    text-align: center;
    margin-left:0px;
    font-size:12px;
    margin-bottom:70px;
  }
  .search-popup .content .search-form
  {
    top:45px;
  }
  .search-popup .cross
  {
    top:80px;
  }
  .search-popup .content button 
  {
    padding:0;
    bottom:10px;
  }
  .search-popup .content input
  {
    padding-bottom:10px;
    font-size:22px;
  }
  .search-popup
  {
    top:80px;
  }
  body #search-icon
  {
    right:50px;

  }
  #search-icon.landing
  {
    display: none !important;
    top:26px;
  }
  .search-input-top img
  {
    left:0;
    width:16px;
    top:0;
    position: relative;
  }
  body .search-input-top input
	{
		display:none;
	}
    .collection-filter
    {
        width:320px;
        font-size:15px;
    }
    .collection-description
    {
        width:90%;
    }
    .title-collection
    {
        font-size:32px;
    }

    
    .select-column img.active
    {
        opacity:1;
    }
    
    .select-column img
    {
        opacity:0.5;
        display:inline-block;
        margin-left:5px;
        margin-right: 5px;
    }
    .select-column
    {
        width:100%;
        text-align: center;
    }
}
.loader
{	

	background:rgba(255,255,255,0.8);
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;

	z-index:6;
}
 .loader img
{
	width:200px;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
}
 .arrow
{
  width:30px;
  height:30px;
  border:1px solid black;
  border-radius:50%;
  display: inline-block;
  position: relative;
  top:7px;
}
 .arrow::before
{
background-image: url(../../../public/assets/images/2021/04/arrow.svg);
	content:' ';
	position:absolute;
	width:20px;
	transition: all .3s cubic-bezier(.55,.055,.675,.19);
    transform: translate3d(-100%,0,0);
    opacity: 0;
	background-size:100% 100%;
	height:20px;
	right:4px;
	top:4px;
	display:block;
}

 .arrow:hover::before
{

transition:all .7s cubic-bezier(.23,1,.32,1) .2s;transform:translateZ(0);opacity:1
}

 .arrow::after
{
background-image: url(../../../public/assets/images/2021/04/arrow.svg);
	content:' ';
	position:absolute;
	width:20px;
	background-size:100% 100%;
	height:20px;
	right:4px;
	top:4px;
	display:block;
	
	    transition: all .7s cubic-bezier(.23,1,.32,1) .2s;
}
.arrow:hover::after

{
transition:all .3s cubic-bezier(.55,.055,.675,.19);transform:translate3d(100%,0,0);opacity:0
	
}
</style>