const formatObjectCollab = (product, variation, cart) => {

    let Parameters= {};

    Parameters.amount = product.price;
    Parameters.quantity = 1;
    Parameters.glass = {};
    Parameters.glass.id = product.id;
    Parameters.cart = {};
    Parameters.cart.id = cart.id;
    Parameters.product_variation_item = {};
    Parameters.product_variation_item.id = variation;
    if(localStorage.senseye_language)
        {
            if(localStorage.senseye_language!="none")
            {
                if(localStorage.senseye_language=="en")
                    {
                        Parameters.lang=3
                    }

                }

            }

    return Parameters;
}

module.exports = formatObjectCollab;