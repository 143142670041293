<template>
  <a  @click="$router.push({ name: 'account' });displaySearch=false"  id="account-icon"  v-bind:class="{collaboration:this.$route.name=='collaboration',landing:true}" v-if="this.$route.name!='checkout-app'">

    <img src="../../../public/assets/images/accountnew.svg" width="23" />
  </a>
  <Search :language="language" @hideSearchPopupEv="hideSearchPopup" @displaySearchPopupEv="displaySearchPopupFunction" :displaySearchPopup="displaySearchPopup" v-if="this.$route.name!='checkout-app'  && this.$route.name!='collaboration-glass'"  />
  <Cart :betaAccess="betaAccess" ref="cartComponent" @displayPopupLoginEv="displayPopupLogin" :language="language" @calculateHeaderEv="calculateHeader" :headerFixed="headerFixed" v-if="this.$route.name!='payment-confirmation'" />
  <div class="lang-select"><a  :class="{active:language=='fr'}" @click="selectLanguage('fr')">FR</a> <a  :class="{active:language=='en'}" @click="selectLanguage('en')">EN</a></div>
</template>

<script>
import Cart from '../elements/Cart.vue'
import Search from '../elements/Search.vue'

export default {
components: { Cart, Search },
name: 'Header',
props: {
  description: String,
  language: String,
  title: String,
  headerFixed:Boolean,
  betaAccess:Boolean,
  name: String,
  displaySearchPopup:Boolean
},
data() {
  return {
   
  }
},
watch:{
    
   
  },
mounted(){
    
},
methods:{
  
  closePopupLogin()
  {
    this.$refs.cartComponent.closePopupLogin();
  },
  selectDevice()
  {
  
    this.$refs.cartComponent.selectDevice();
  },
  userLogin(user)
  {
    this.$refs.cartComponent.userLogin(user);
  },
  reloadUserContent()
  {
    this.$refs.cartComponent.reloadUserContent()
    
  },
  reloadUserContentCheckout()
  {
    this.$refs.cartComponent.reloadUserContentCheckout()
    
  },

  addToCartCollection(product,options,prescription)
  {
    this.$refs.cartComponent.addToCartCollectionEvent(product,options,prescription);
  },

  displayPopupLogin(args) {

    this.$emit('displayPopupLoginEv', args)

  },

  selectLanguage(lang){
    this.$emit('changeLanguageEv', lang)
  },
  hideSearchPopup(){
    
      this.$emit('hideSearchPopupEv')
    },
    displaySearchPopupFunction(){
    
    this.$emit('displaySearchPopupEv')
  },
  calculateHeader() {
    
    this.$emit('calculateHeaderEv')
  }
}
}
</script>

<style scoped>
@media screen and (max-width:760px){
  .lang-select {
    display: none;
  }
}
.lang-select {
  position:absolute;
  right:30px;
  z-index:102;
  top:28px;
}
.lang-select a.active {
  color:#000;
}
.lang-select a {
  text-decoration: none;
  cursor: pointer;
  margin-right:15px;
  color:#ACACAC;
  font-size:15px;
}
#account-icon.landing {
top:30px;
}
#account-icon
{
  text-decoration: none;
  font-size:15px;
  color:#000;
  position: absolute;
    right: 375px;
    top: 25px;
  
    cursor: pointer;
    z-index: 102;
}
.page-template-home-closed #account-icon
{
  display:none !important;
}
.page-template-template-landing #account-icon
{
  display:none !important;
}

</style>