import { defineStore } from 'pinia';
import { APIRequest } from "@simb2s/senseye-sdk";
import formatObjectCollab from '../utils/objects/orderitemObject';
import formatObjectCollection from '../utils/objects/orderitemCollectionObject';
import { useCartStore } from './cartStore';
import { useGlassStore } from './glassStore';


export const useOrderItemStore = defineStore('orderItem', {
    state: () => ({
        orderItemLoaded: false,
        loadingOrderItems: false,
        useCart: useCartStore(),
        useGlass: useGlassStore(),
        addingToCart:false,
        cartItem: [],
        cartID: localStorage.cart_senseye_id,
        orderItemsFromStorage: localStorage.order_items ? JSON.parse(localStorage.order_items) : [],
    }),
    actions: {

        async addOrderItemCollab(id, variation){
            const product = await this.useGlass.getSingleGlass(id);
            const alreadyInCart = false;
            // console.log(product);
            // console.log(alreadyInCart);

            if(!alreadyInCart){
              
                this.createOrderItemCollab(product, variation);
            }else{

                this.createOrderItemCollab(product, variation);
                // Find a way to get the orderItem collab from glass id
            }
        },
        async setLoadingOrderItems(){
            this.loadingOrderItems=true
        },
        async addOrderItemCollection(product, options){
            this.addingToCart=true
            const product_detail = await this.useGlass.getSingleGlass(product);
            this.createOrderItemCollection(product_detail, options);
        },
        async createOrderItemCollab(product, variation) {
            try {
                this.orderItemLoaded = false;
                const cart = this.useCart.cart;
                let cartItem = formatObjectCollab(product, variation, cart);
                // console.log(this.cartItem);
                let orderItem = await APIRequest.create('order-items', cartItem);

                delete orderItem.cart
                // console.log(orderItem);
                this.cartItem = [...this.cartItem, orderItem];
               
                await this.saveOrderItemCollabToStorage();
                return orderItem;
            } catch (err) {
                console.log(err);
            } finally {
                this.orderItemLoaded = true;
            }
        },
        async createOrderItemCollection(product, options) {
            try {

                this.orderItemLoaded = false;
                const cart = this.useCart.cart;
                let cartItem = formatObjectCollection(product, options, cart);
                let orderItem = await APIRequest.create('order-items', cartItem);
                delete orderItem.cart
                this.cartItem = [...this.cartItem, orderItem];
               
                await this.saveOrderItemCollabToStorage();
                
                return orderItem;
            } catch (err) {
                console.log(err);
            } finally {
                this.orderItemLoaded = true;
                this.addingToCart=false
            }
        },
        async deleteOrderItemCollab(id) {
            
            await APIRequest.delete('order-items', id);
            this.saveOrderItemCollabToStorage();
        },
        async increaseOrderItemCollab(id) {

            this.cartItem.forEach(item => {
                if (item.id === id) {

                    let amount_ind=item.amount/item.quantity
                    item.amount = item.amount*1 + amount_ind*1
                    item.quantity++;

                }
            }
            )
            //return this.cartItem
            this.saveOrderItemCollabToStorage();
        },
        async decreaseOrderItemCollab(id) {
            for (const item of this.cartItem){
                if (item.id === id){
                    if(item.quantity > 1){
                        let amount_ind=item.amount/item.quantity
                        item.amount = item.amount*1 - amount_ind*1

                        item.quantity--;
                    }else{
                        await this.clearOrderItemCollab(id);
                    }
                }
            }
            this.saveOrderItemCollabToStorage();
        },
        async updateOrderItemScan(id, scan,name) {
            for (const item of this.cartItem){
                if (item.id === id){
                    if(scan==null)
                    {
                        item.scan=null
                        if(name==null)
                        {
                            item.size="onmeasure";
                        }
                    }
                    else
                    {
                        item.scan={};
                        item.scan.id=scan
                        item.scan.name=name
                    }
                    
                }
            }
            this.saveOrderItemCollabToStorage();
        },
        async updateOrderItemSize(id, size) {
            for (const item of this.cartItem){
                if (item.id === id){
                    item.size=size
                }
            }
            this.saveOrderItemCollabToStorage();
        },
        async clearOrderItemCollab(id){
            const index = this.cartItem.findIndex(item =>  item.id === id);
            this.cartItem.splice(index, 1);

            await this.deleteOrderItemCollab(id);
            this.saveOrderItemCollabToStorage();
        },
        async getOrderitemCollab(id) {
            try {
                this.orderItemLoaded = false;
                const orderitem = await APIRequest.detail('order-items', id);
                return orderitem
            } catch (err) {
                console.log(err);
            } finally {
                this.orderItemLoaded = true;
            }
        },
        async updateOrderItemsFromCart(id=null) {
       
            
            if(id) {
                this.cartID=id
            }
            
               
            if(localStorage.senseye_language)
                {
                    if(localStorage.senseye_language!="none")
                    {
                        if(localStorage.senseye_language=="en")
                            {
                                var request = '?cart=' + this.cartID+"?lang=3";
                            }
                            else
                            {
                                var request = '?cart=' + this.cartID;
                            }
                    }
                    else
                    {
                        var request = '?cart=' + this.cartID;
                    }
                }
                else
                {
                    var request = '?cart=' + this.cartID;
                }
            
            
            

            const cart_items = await APIRequest.list_with_request('order-items', request)

            const items = cart_items[0];
            // When items OK WE MAKE A NEW API CALL TO GET ALL THE GLASSES AND DISPLAY THEM TO CARTITEM COMPONENT
            let itemsArray = [];

            for (const item of items){

           

                let order_item = await this.getOrderitemCollab(item.id);
                delete order_item.cart
                delete order_item.user
                delete order_item.order

                if(order_item.glass!=null && order_item.glass) {
                    let object={}
                    let object2=order_item.glass
                    object.price=object2.price
                    object.name=object2.name
                    object.id=object2.id
                    object.custom_fit=object2.custom_fit
                    object.type=object2.type

                    order_item.glass=object
                }
                
                if(order_item.scan!=null && order_item.scan) {
                    let scan_object={}
                    scan_object.id=order_item.scan.id
                    scan_object.name=order_item.scan.name
                    order_item.scan=scan_object
                }
                if(order_item.glass_type!=null && order_item.glass_type) {
                    let glass_type_object={}
                    glass_type_object.id=order_item.glass_type.id
                    glass_type_object.price=order_item.glass_type.price
                    glass_type_object.name=order_item.glass_type.name
                    order_item.glass_type=glass_type_object
                }
                if(order_item.glass_option!=null && order_item.glass_option) {
                    let glass_option_object={}
                    glass_option_object.id=order_item.glass_option.id
                    glass_option_object.price=order_item.glass_option.price
                    glass_option_object.name=order_item.glass_option.name
                    order_item.glass_option=glass_option_object
                }
                if(order_item.tint!=null && order_item.tint) {
                    let object={}
                    let object2=order_item.tint
                    object.id=object2.id
                    object.price=object2.price
                    object.name=object2.name

                    order_item.tint=object
                }
                if(order_item.color!=null && order_item.color) {
                    let object={}
                    let object2=order_item.color
                    object.id=object2.id
                    object.price=object2.price
                    object.name=object2.name

                    order_item.color=object
                }
                if(order_item.texture!=null && order_item.texture) {
                    let object={}
                    let object2=order_item.texture
                    object.id=object2.id
                    object.price=object2.price
                    object.name=object2.name

                    order_item.texture=object
                }
                if(order_item.texture_style!=null && order_item.texture_style) {
                    let object={}
                    let object2=order_item.texture_style
                    object.id=object2.id
                    object.price=object2.price
                    object.name=object2.name

                    order_item.texture_style=object
                }
                if(order_item.branch_texture!=null && order_item.branch_texture) {
                    let object={}
                    let object2=order_item.branch_texture
                    object.id=object2.id
                    object.price=object2.price
                    object.name=object2.name

                    order_item.branch_texture=object
                }
                if(order_item.branch_color!=null && order_item.branch_color) {
                    let object={}
                    let object2=order_item.branch_color
                    object.id=object2.id
                    object.price=object2.price
                    object.name=object2.name

                    order_item.branch_color=object
                }
               
                itemsArray = [...itemsArray, order_item]
            }

        
            this.cartItem=itemsArray
            this.calculateCartAmount()
            this.orderItemsFromStorage=itemsArray
        },
        async getOrderitemListCollab() {

            

            
            if(localStorage.senseye_language)
                {
                    if(localStorage.senseye_language!="none")
                    {
                        if(localStorage.senseye_language=="en")
                            {
                                var request = '?cart=' + this.cartID+"?lang=3";
                            }
                            else
                            {
                                var request = '?cart=' + this.cartID;
                            }
                    }
                    else
                    {
                        var request = '?cart=' + this.cartID;
                    }
                }
                else
                {
                    var request = '?cart=' + this.cartID;
                }
            
            

             

                const cart_items = await APIRequest.list_with_request('order-items', request)

                const items = cart_items[0];

         
                // When items OK WE MAKE A NEW API CALL TO GET ALL THE GLASSES AND DISPLAY THEM TO CARTITEM COMPONENT
                let itemsArray = [];

                for (const item of items){
                 

                    let order_item = await this.getOrderitemCollab(item.id);


                    delete order_item.cart
                    delete order_item.order
                    delete order_item.user

                    let order_item_glass

                    if(order_item.glass!=null && order_item.glass ) {

                     
                        let object={}
                        let object2=order_item.glass
                        object.price=object2.price
                        object.name=object2.name
                        object.custom_fit=object2.custom_fit
                        object.id=object2.id
                        object.type=object2.type

                        order_item_glass=object

                      

                    }
                    

                    delete order_item.glass
                 
                    let order_item_new={...order_item}
                    
                    if(order_item.scan!=null && order_item.scan) {
                        let scan_object={}
                        scan_object.id=order_item.scan.id
                        scan_object.name=order_item.scan.name
                        order_item_new.scan=scan_object
                    }
                    if(order_item.glass_type!=null && order_item.glass_type) {
                        let glass_type_object={}
                        glass_type_object.id=order_item.glass_type.id
                        glass_type_object.price=order_item.glass_type.price
                        glass_type_object.name=order_item.glass_type.name
                        order_item_new.glass_type=glass_type_object
                    }
                    if(order_item.glass_option!=null && order_item.glass_option) {
                        let glass_option_object={}
                        
                        glass_option_object.id=order_item.glass_option.id
                        glass_option_object.price=order_item.glass_option.price
                        glass_option_object.name=order_item.glass_option.name
                        order_item_new.glass_option=glass_option_object
                    }
                    if(order_item.tint!=null && order_item.tint) {
                        let object={}
                        let object2=order_item.tint
                        object.id=object2.id
                        object.price=object2.price
                        object.name=object2.name

                        order_item_new.tint=object
                    }
                    if(order_item.color!=null && order_item.color) {
                        let object={}
                        let object2=order_item.color
                        object.id=object2.id
                        object.price=object2.price
                        object.name=object2.name

                        order_item_new.color=object
                    }
                    if(order_item.texture!=null && order_item.texture) {
                        let object={}
                        let object2=order_item.texture
                        object.id=object2.id
                        object.price=object2.price
                        object.name=object2.name

                        order_item_new.texture=object
                    }
                    if(order_item.texture_style!=null && order_item.texture_style) {
                        let object={}
                        let object2=order_item.texture_style
                        object.id=object2.id
                        object.price=object2.price
                        object.name=object2.name

                        order_item_new.texture_style=object
                    }
                    if(order_item.branch_texture!=null && order_item.branch_texture) {
                        let object={}
                        let object2=order_item.branch_texture
                        object.id=object2.id
                        object.price=object2.price
                        object.name=object2.name

                        order_item_new.branch_texture=object
                    }
                    if(order_item.branch_color!=null && order_item.branch_color) {
                        let object={}
                        let object2=order_item.branch_color
                        object.id=object2.id
                        object.price=object2.price
                        object.name=object2.name

                        order_item_new.branch_color=object
                    }

                    
                    
                   
                    order_item_new.glass=order_item_glass


                    itemsArray = [...itemsArray, order_item_new]

                }


          
                this.cartItem=itemsArray
                this.calculateCartAmount()


               

            return this.orderItemsFromStorage;
        },
        async updateOrderItemCollab(){
            try{

          
                this.orderItemLoaded = false;
                for (const item of [...this.cartItem]){
                    
                    if(item.cart)
                    {
                        let id_cart=item.cart.id
                        delete item.cart
                        delete item.user
                        delete item.order
                        item.cart={id:id_cart}
                    }
                    if(item.user)
                    {
                        let id_cart=item.user.id
                       
                        delete item.user
                       
                        item.user={id:id_cart}
                    }
                    if(item.order)
                    {
                        let id_cart=item.order.id
                      
                        delete item.order
                        item.order={id:id_cart}
                    }

                    if(item.glass)
                    {
                        let id_glass=item.glass.id
                        let name_glass=item.glass.name
                        let custom_fit=item.glass.custom_fit
                        delete item.glass
                        item.glass={id:id_glass,name:name_glass,custom_fit:custom_fit}
                    }

                    if(item.order_item_customizations)
                    {

                        let order_item_customizations_new=[]

                        for(let i=0;i<item.order_item_customizations.length;i++)
                        {
                            let order_item_custom=item.order_item_customizations[i]
                            if(order_item_custom.model_part)
                            {

                                if(order_item_custom.model_part.glass)
                                {
                                    let glass_id_model_part=order_item_custom.model_part.glass.id
                                    delete order_item_custom.model_part.glass
                                    order_item_custom.model_part.glass={id:glass_id_model_part}
                                }


                            }
                            order_item_customizations_new.push(order_item_custom)
                        }

                       
                        delete item.order_item_customizations
                        item.order_item_customizations=order_item_customizations_new
                    }

                    await APIRequest.update('order-items', item.id, item)
                }
            }catch(err){
                console.log(err);
            }finally{
                this.orderItemLoaded = true;
                this.loadingOrderItems=false;
            }
        },
        saveOrderItemCollabToStorage(){
            // localStorage.order_items = JSON.stringify(this.orderItemsFromStorage);
            // this.orderItemsFromStorage = JSON.parse(localStorage.order_items);
            // // console.log(this.orderItemsFromStorage);
            // this.cartItem = this.orderItemsFromStorage;
            this.calculateCartAmount()
        },
        calculateCartAmount() {
            let amount=0
            for(let i=0;i<this.cartItem.length;i++) {
                amount+=this.cartItem[i].amount
            }
            this.useCart.updateCartAmount(amount)
        },
        async deleteFromSocket(order_item_id) {
            await this.clearOrderItemCollab(order_item_id)
        },
        updateFromSocket(order_item_id) {

        },
        resetStore() {
            this.orderItemLoaded = false;
            this.cartItem = [];
        }
    }
}
)