<template>

   <div class="header-top">
    
     <div class="text" v-if="language=='fr'">
       REJOINS LE <a href="/collection-beta-new">PROGRAMME BÊTA</a>
     </div>
     <div class="text" v-else>
      JOIN THE <a href="/collection-beta-new">BÊTA PROGRAM</a>
     </div>
   </div>
 
</template>

<script>

import jQuery from 'jquery'

export default {
  components: {  },
  name: 'HeaderTop',
  props: {
    // description: String,
    language: String,
    // name: String
  },
  data() {
    return {
      loop: 2
    }
  },
  mounted(){
   

  },
  methods:{
    
  }
}
</script>
<style scoped>
@media screen and (max-width:760px)
{
  .header-top
  {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .header-top .text {
    font-size:10px !important;
    font-weight: 600;
  }
}
.header-top .text {
  font-size:15px;
  font-weight: 600;
}
.header-top
{
 text-align: center;
  padding-top:16px;
padding-bottom: 16px;
z-index: 2;
  position: relative;
  color:#fff;
  background:#000;
  width:100%;
}

a 
{
  color:#fff;
}

</style>